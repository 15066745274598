/* eslint-disable */
// favicon.ico
import './../favicon.ico';
// css
import '../../node_modules/swiper/dist/css/swiper.css'
import 'angular-material/angular-material.css';
import 'angular-color-picker/angular-color-picker.css';
import './../css/toggle-switch.css';
import './../scss/main.scss';
import './../img/images';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

// js 3rd part module
import _ from 'lodash';
import angular from 'angular';
import uiRoute from '@uirouter/angularjs';
import angularSanitize from 'angular-sanitize';
import angularAsyncAwait from 'angular-async-await';
import restangular from 'restangular';
import angularLocalStorage from 'angular-local-storage';
import angularFileUpload from 'ng-file-upload';
import angularLoadingBar from 'angular-loading-bar';
import ngDialog from 'ng-dialog';
import canvasToBlob from 'blueimp-canvas-to-blob';
import angularUIBootstrap from 'angular-ui-bootstrap';
import gmDatepickerMultiSelect from 'gm.datepicker-multi-select'
import ngMap from 'ngmap';
import ngAnimate from 'angular-animate';
import angularDragAndDropLists from 'angular-drag-and-drop-lists';
import angularColorPicker from 'angular-color-picker';
import angulrChartjs from 'angular-chart.js';
import uiCropper from 'ui-cropper';
import ngClipboard from 'ngclipboard';
import angulari18n from 'angular-i18n/angular-locale_zh-tw.js';
import angularMaterial from 'angular-material/angular-material.js';
import angularMessages from 'angular-messages';
import angularAria from 'angular-aria';
import { react2angular } from 'react2angular';
import quill from 'quill';
import ngQuill from 'ng-quill';

// config
import ga from './config/google-analytics';
import routeConfig from './config/route';
import { tokenRefreshInterceptorConfig, tokenRefreshInterceptor } from './config/token-refresh';
import restApiConfig from './config/rest';
import compileProvider from './config/compile';
import materialThemeConfig from './config/material-theme';
import customNgQuillConfigProvider from './config/custom-ng-quill-config-provider';

import ReservationListComponent from './react/ReservationListComponent';
import UnorderedReservationListComponent from './react/UnorderedReservationListComponent';

// factory
import session from './factory/session';
import currentUser from './factory/current-user';
import ywUtil from './factory/yw-util';
import address from './factory/address';
import ywDialog from './factory/yw-dialog';
import confirmDialog from './factory/confirm-dialog';
import ywEvent from './factory/yw-event';
import ywPager from './factory/yw-pager';
import ywTimezone from './factory/yw-timezone';
import serverVersion from './factory/server-version';
import searchableObjPickerDialog from './factory/searchable-obj-picker-dialog';
import multiSearchableObjPickerDialog from './factory/multi-searchable-obj-picker-dialog';
import timelineDialog from './factory/timeline-dialog';
import reservationUtil from './factory/reservation-util';
import unorderedReservationUtil from './factory/unordered-reservation-util';
import newebpayDetailDialog from './factory/newebpay-detail-dialog';
import articleUtil from './factory/article-util';
import albumUtil from './factory/album-util';
import venderUtil from './factory/vender-util';
import filterItemFactory from './factory/filter-item-factory';
import serviceEditDialog from './factory/service-edit-dialog';
import imageUploadDialog from './factory/image-upload-dialog';
import sinyiStaffInfoUtil from './factory/sinyi-staff-info-util';
import labelManagement from './factory/label-management';
import layoutModuleUtils from './factory/layout-module-util';

// life factory
import lifeDialog from './life-factory/life-dialog';
import lifePageUtil from './life-factory/life-page-util';
import lifeUnorderedUtil from './life-factory/life-unordered-reservation-util';

// non used factory
import paymentsSelectionDialog from './factory/payments-selection-dialog';
import WebSocket from './factory/WebSocket';
import popupSelectionDialog from './factory/popup-selection-dialog';
import mapUtil from './factory/map-util';
import uuid from './factory/uuid';
import orderUtil from './factory/order-util';
import activityDetailDialog from './factory/activity-detail-dialog';
import multiObjPickerDialog from './factory/multi-obj-picker-dialog';
import shortUrl from './factory/short-url';
import firebaseAnalytics from './factory/firebase-analytics';
import checkboxDialog from './factory/checkbox-dialog';
import subcatalogEditorDialog from './factory/subcatalog-editor-dialog';

// service
import User from './resource/user';
import UserGroup from './resource/service-admin/user-group';
import Vender from './resource/service-admin/vender';
import QualityTag from './resource/service-admin/quality-tag.js';
import ServiceClassification from './resource/service-admin/service-classification';
import AdvancedFilterItem from './resource/service-admin/advanced-filter-item';
import Account from './resource/service-admin/account';
import Service from './resource/service-admin/service';
import ServiceCapacity from './resource/service-admin/service-capacity';
import Order from './resource/service-admin/order';
import Reservation from './resource/service-admin/reservation';
import UnorderedReservation from './resource/service-admin/unordered-reservation';
import CouponEvent from './resource/service-admin/coupon-event';
import Coupon from './resource/service-admin/coupon';
import Quotation from './resource/service-admin/quotation';
import Banner from './resource/service-admin/banner';
import NotificationGroup from './resource/service-admin/notification-group';
import ArticleClassification from './resource/service-admin/article-classification';
import Article from './resource/service-admin/article';
import Keyword from './resource/service-admin/keyword';
import Catalog from './resource/service-admin/catalog';
import VenderStaff from './resource/vender/vender-staff';
import AdminNotifications from './resource/admin/admin-notifications';
import Album from './resource/service-admin/album';
import PhotoTag from './resource/service-admin/photo-tag';
import Photo from './resource/service-admin/photo';
import TA3Banner from './resource/service-admin/ta3-banner';
import LayoutModule from './resource/service-admin/layout-module';
import Layout from './resource/service-admin/layout';
import VenderContact from './resource/service-admin/vender-contact';
import Bill from './resource/service-admin/bill';
import AccountTransactionDetail from './resource/service-admin/accountTransactionDetail';

// import BuildingOptions from './resource/current/building-options';
// import GasMeter from './resource/current/gas-meter';
// import Guest from './resource/current/guest';
// import LocalInfo from './resource/current/local-info';
// import LocalStore from './resource/current/local-store';
// import LocalStoreApplication from './resource/current/local-store-application';
// import ResidentApplication from './resource/current/resident-application';
// import FacilitySetting from './resource/current/facility-setting';
// import FacilityReservation from './resource/current/facility-reservation';
// import UserCredit from './resource/current/user-credit';
// import ServiceContact from './resource/current/service-contact';
// import Vote from './resource/current/vote';
// import Community from './resource/admin/community';
// import Distributor from './resource/admin/distributor';
// import PropertyApplication from './resource/admin/property-application';
// import ServiceProvider from './resource/admin/service-provider';
// import ServiceProviderAccounts from './resource/admin/service-provider-accounts';
// import TransportationCompany from './resource/admin/transportation-company';
// import ToBuyActivity from './resource/admin/to-buy-activity';
// import ToBuyOrder from './resource/service-provider/to-buy-order';
// import ToBuyStore from './resource/service-provider/to-buy-store';
// import ToBuyChainStore from './resource/service-provider/to-buy-chain-store';
// import ToBuyStoreProduct from './resource/service-provider/to-buy-store-product';
// import ToBuyTag from './resource/service-provider/to-buy-tag';
// import ToBuyStoreAccounts from './resource/service-provider/to-buy-store-accounts';
// import ToBuyStoreLinks from './resource/service-provider/to-buy-store-links';
// import ToBuyStoreNotice from './resource/to-buy-store-notice';
// import Landmark from './resource/landmark';
// import Coupon from './resource/coupon';
// import Region from './resource/region';
// import SystemNotice from './resource/admin/system-notice';
// import PushMessage from './resource/admin/push-message';
// import PushMessageGroup from './resource/admin/push-message-group';
// import GroupBuy from './resource/group-buy';
// import GroupBuySystemGroup from './resource/group-buy-system-group';
// import GroupBuyCopySystemGroup from './resource/group-buy-copy-system-group';
// import SalaryPolicy from './resource/salary-policy';
// import CourierSettlement from './resource/service-provider/courier-settlement';
// import CourierTransferRecords from './resource/service-provider/courier-transfer-records';
// import ToBuyBills from './resource/admin/to-buy-bills';
// import ToBuyTagSynonym from './resource/admin/to-buy-tag-synonym';
// import DeniedAddress from './resource/denied-address';
// import ExtraService from './resource/extra-service';
// import ExtraServiceApplication from './resource/extra-service-application';
// import ToBuyStoreStatistic from './resource/to-buy-store-statistic';
// import UploadFileApplications from './resource/admin/upload-file';
// import StorePicturesCheck from './resource/admin/store-pictures-check';
// import PointsLog from './resource/admin/point-logs';
// import UsageOfPoints from './resource/admin/usage-of-points';
// import Commentary from './resource/commentary';
// import WaitingPoint from './resource/admin/waiting-point';
// import ToBuyStoreGroup from './resource/admin/to-buy-store-group';
// import CourierRewardPrograms from './resource/admin/courier-reward-programs';
// import ShiftRecords from './resource/shift-records';
// import ToBuyDiscountGroup from './resource/admin/to-buy-discount-group';
// import Payments from './resource/payments';
// import Families from './resource/families';
// import AutoPayment from './resource/moderator/auto-payment';
// import CustomService from './resource/admin/custom-service';
// import PostEvent from './resource/post-event';
// import PostComment from './resource/post-comment';
// import Banner from './resource/admin/banner';
// import Games from './resource/games';
// import Gifts from './resource/admin/gift-box';
// import CommunityTags from './resource/community-tags';
// import PostBlacklist from './resource/admin/post-blacklist';
// import Bill from './resource/admin/bill';
// import CoopStorePost from './resource/admin/coop-store-post';
// import CommunityNews from './resource/admin/community-news';
// import CommunityGroup from './resource/admin/community-group';
// import DomesticService from './resource/admin/domestic-service';
// import SinyiStore from './resource/admin/sinyi-store';
// import SinyiStoreItem from './resource/admin/sinyi-store-item';
// import SinyiCommunity from './resource/admin/sinyi-community';
// import SinyiStoreServPool from './resource/admin/sinyi-store-service-pool';
// import SinyiStoreService from './resource/admin/sinyi-store-service';
// import VillagePost from './resource/admin/village-post';
// import LuckyWheel from './resource/admin/lucky-wheel';
// import LuckyWheelPrizeCode from './resource/admin/lucky-wheel-prize-code';
// import EventSetting from './resource/admin/event-setting';
// import GiftCode from './resource/admin/gift-code';

// filter
import paymentMethod from './filter/payment-method';
import shortAddr from './filter/short-addr';
import userRole from './filter/user-role';

// directive
import lsEditor from './directive/ls-editor';
import ywPopupSelection from './directive/yw-popup-selection';
import ywPopupMultiSelection from './directive/yw-popup-multi-selection.js';
import searchableObjPicker from './directive/searchable-obj-picker';
import multiSearchableObjPicker from './directive/multi-searchable-obj-picker';
import ywUpload from './directive/yw-upload';
import ywDatePickerInput from './directive/yw-date-picker-input';
import ywTimePickerInput from './directive/yw-time-picker-input';
import timelineInput from './directive/timeline-input';
import serviceCapacitySetting from './directive/service-capacity-setting';
import couponEventDetail from './directive/coupon-event-detail';
import ywProductCustomization from './directive/yw-product-customization';
import customizationItems from './directive/customization-items';
import webviewManagement from './directive/webview-management';
import cancelledWordManagement from './directive/cancelled-word-management';
import ywDropzone from './directive/yw-dropzone';
import colorSelection from './directive/color-selection';
import tabWatcher from './directive/tab-watcher.js';

// non used directive
import dayPicker from './directive/day-picker';
import serviceTimePicker from './directive/service-time-picker';
import ywUserSelector from './directive/yw-user-selector';
import singleCoordinateInput from './directive/single-coordinate-input';
import ywTagSetting from './directive/yw-tag-setting';
import dropdownSelection from './directive/dropdown-selection';
import ywTimePeriodValuePreview from './directive/yw-time-period-value-preview';
import pushMessage from './directive/push-message';
import productOrderSetupButton from './directive/product-order-setup-btn';

// components
import iconButton from './component/table/icon-button';
import tableCheckbox from './component/table/table-checkbox';
import tableImgPreview from './component/table/table-img-preview';
import imageViewer from './component/image-viewer';
import labelManagementTable from './component/label-management-table';
// 訂單明細元件
import reservationUtmInfo from './component/reservation-utm-info';
import reservationEnvInfo from './component/reservation-env-info';
import reservationSinyiStaffInfo from './component/reservation-sinyi-staff-info';
import reservationServiceInfo from './component/reservation-service-info';
import reservationUserImage from './component/reservation-user-image';
import reservationVenderProcessingImage from './component/reservation-vender-processing-image';
import reservationVenderMemoImage from './component/reservation-vender-memo-image';
import reservationNote from './component/reservation-note';
import unorderedReservationServiceInfo from './component/unordered-reservation-service-info';
import reservationSinyiStaffEditIcon from './component/reservation-sinyi-staff-edit-icon';
import reservationSelfConstructButton from './component/reservation-self-construct-button';
import reservationVisibleButton from './component/reservation-visible-button';
import reservationTerminateFeeButton from './component/reservation-terminate-fee-button';
import reservationNotifyButton from './component/reservation-notify-button';
import reservationReferralFeeUpdateButton from './component/reservation-referral-fee-update-button';
import reservationReferralFeeInfoButton from './component/reservation-referral-fee-info-button';
import reservationGuidePurchase from './component/reservation-guide-purchase';
import reservationVatInfo from './component/reservation-vat-info';
// 新定義元件，後台頁面以外的單獨頁面使用
import lifeButton from './life-component/life-button';
import lifeFooter from './life-component/life-footer';
import lifeTab from './life-component/life-tab';
import lifeTag from './life-component/life-tag';
import lifeDetailMenu from './life-component/life-detail-menu';
import lifeStatusButton from './life-component/life-status-button';
import lifeInput from './life-component/life-input';
import lifeLabelInput from './life-component/life-label-input';
import lifeTextarea from './life-component/life-textarea';
import lifeRadio from './life-component/life-radio';
import lifeDialogFooter from './life-component/life-dialog-footer';
import lifeSelect from './life-component/life-select';
import lifeCheck from './life-component/life-check';
import lifeCalendar from './life-component/life-calendar';
import lifeDatePicker from './life-component/life-date-picker';
import lifeToast from './life-component/life-toast';

import imgCropAndUpload from './directive/img-crop-and-upload';
import sipNumberRegister from './directive/sip-number-register';
import navigationSetting from './directive/navigation-setting';
import imagePreviewDialog from './directive/image-preview-dialog';
import ywSwitch from './directive/yw-switch';
import textWithEditDialog from './directive/text-with-edit-dialog';
import urlShorter from './directive/url-shorter';
import dynamicLinkGenerater from './directive/dynamic-link-generater';
import timePeriodInWeek from './directive/time-period-in-week';
import timepartSetting from './directive/timepart-setting';
import selectTimeInWeek from './directive/select-time-in-week';
import timepartPicker from './directive/timepart-picker';
import ywLoaded from './directive/yw-loaded';
import ngCkeditor from './directive/ng-ckeditor';

import websiteContentPage from './component/website-content-page';

// controller
import mainCtrl from './controller/main';
import leftNavCtrl from './controller/left-nav';
import headerCtrl from './controller/header';
import loginCtrl from './controller/login';
import mainActionCtrl from './controller/mainAction';
import baseListCtrl from './controller/base/list';
import baseActionCtrl from './controller/base/action';
// admin controller
import adminCtrl from './controller/admin';
import serviceAccountController from './controller/admin/service-account';
// service admin controller
import serviceAdminController from './controller/service-admin';
import saVenderController from './controller/service-admin/vender';
import saVenderListController from './controller/service-admin/vender/list';
import saVenderActionController from './controller/service-admin/vender/action';
import saVenderQualityTagController from './controller/service-admin/quality-tag';
import saVenderQualityTagListController from './controller/service-admin/quality-tag/list';
import saVenderQualityTagActionController from './controller/service-admin/quality-tag/action';
import saServiceClassificationController from './controller/service-admin/service-classification';
import saServiceClassificationListController from './controller/service-admin/service-classification/list';
import saServiceClassificationActionController from './controller/service-admin/service-classification/action';
import saAdvancedFilterItemController from './controller/service-admin/service-classification/advanced-filter-item';
import saAdvancedFilterItemListController from './controller/service-admin/service-classification/advanced-filter-item/list';
import saAdvancedFilterItemActionController from './controller/service-admin/service-classification/advanced-filter-item/action';
import saVenderAccountController from './controller/service-admin/vender-account';
import saVenderAccountListController from './controller/service-admin/vender-account/list';
import saVenderAccountActionController from './controller/service-admin/vender-account/action';
import saVenderAdvancedFilterItemController from './controller/service-admin/vender/advanced-filter-item';
import saServiceController from './controller/service-admin/service';
import saServiceListController from './controller/service-admin/service/list';
import saServiceActionController from './controller/service-admin/service/action';
import saBillController from './controller/service-admin/bill';
import saBillListController from './controller/service-admin/bill/list';
import saAccountController from './controller/service-admin/account';
import saAccountListController from './controller/service-admin/account/list';
import saAccountTransactionDetailController from './controller/service-admin/accountTransactionDetail';
import saAccountTransactionDetailListController from './controller/service-admin/accountTransactionDetail/list';
import saReservationController from './controller/service-admin/reservation';
import saReservationListController from './controller/service-admin/reservation/list';
import saUnorderedReservationController from './controller/service-admin/unordered-reservation';
import saUnorderedReservationListController from './controller/service-admin/unordered-reservation/list';
import saUnorderedReservationQuotationController from './controller/service-admin/unordered-reservation/quotation';
import saReservationQuotationController from './controller/service-admin/reservation/quotation';
import saReservationQuotationListController from './controller/service-admin/reservation/quotation/list';
import saReservationQuotationActionController from './controller/service-admin/reservation/quotation/action';
import saCouponEventController from './controller/service-admin/coupon-event';
import saCouponEventListController from './controller/service-admin/coupon-event/list';
import saCouponController from './controller/service-admin/coupon';
import saCouponListController from './controller/service-admin/coupon/list';
import saSystemSettingController from './controller/service-admin/system-setting';
import saBannerController from './controller/service-admin/banner';
import saBannerListController from './controller/service-admin/banner/list';
import saBannerActionController from './controller/service-admin/banner/action';
import saTA3BannerController from './controller/service-admin/ta3-banner';
import saTA3BannerListController from './controller/service-admin/ta3-banner/list';
import saTA3BannerActionController from './controller/service-admin/ta3-banner/action';
import saNotificationController from './controller/service-admin/notification';
import saNotificationListController from './controller/service-admin/notification/list';
import saNotificationActionController from './controller/service-admin/notification/action';
import saCustomerController from './controller/service-admin/customer';
import saCustomerListController from './controller/service-admin/customer/list';
import saCustomerActionController from './controller/service-admin/customer/action';
import saCustomerGroupController from './controller/service-admin/customer-group';
import saCustomerGroupListController from './controller/service-admin/customer-group/list';
import saCustomerGroupActionController from './controller/service-admin/customer-group/action';
import saArticleClassificationController from './controller/service-admin/article-classification';
import saArticleClassificationListController from './controller/service-admin/article-classification/list';
import saArticleClassificationActionController from './controller/service-admin/article-classification/action';
import saArticleController from './controller/service-admin/article';
import saArticleListController from './controller/service-admin/article/list';
import saArticleActionController from './controller/service-admin/article/action';
import saKeywordController from './controller/service-admin/keyword';
import saKeywordListController from './controller/service-admin/keyword/list';
import saKeywordActionController from './controller/service-admin/keyword/action';
import saWebsiteContentLayoutController from './controller/service-admin/website-content/layout';
import saWebsiteContentAlbumController from './controller/service-admin/website-content/album';
import saCatalogController from './controller/service-admin/catalog';
import saCatalogListController from './controller/service-admin/catalog/list';
import saCatalogActionController from './controller/service-admin/catalog/action';
import saAlbumController from './controller/service-admin/album';
import saAlbumListController from './controller/service-admin/album/list';
import saAlbumActionController from './controller/service-admin/album/action';
import saPhotoTagController from './controller/service-admin/photo-tag';
import saPhotoTagListController from './controller/service-admin/photo-tag/list';
import saPhotoTagActionController from './controller/service-admin/photo-tag/action';
import saPhotoController from './controller/service-admin/photo';
import saPhotoListController from './controller/service-admin/photo/list';
import saPhotoActionController from './controller/service-admin/photo/action';
import saLayoutModuleController from './controller/service-admin/layout-module';
import saLayoutModuleListController from './controller/service-admin/layout-module/list';
import saLayoutController from './controller/service-admin/layout';
import saLayoutListController from './controller/service-admin/layout/list';
import saLayoutActionController from './controller/service-admin/layout/action';
// vender controller
import venderController from './controller/vender'
import venderVenderStaffController from './controller/vender/vender-staff';
import venderVenderStaffListController from './controller/vender/vender-staff/list';
import venderVenderStaffActionController from './controller/vender/vender-staff/action';
import venderAccountSettingController from './controller/vender/account-setting';
import venderAccountSettingActionController from './controller/vender/account-setting/action';
// 後台頁面以外的單獨頁面的 controller
import reservationDetailController from './controller/life/reservationDetail'
import unorderedReservationDetailController from './controller/life/unorderedReservationDetail'


// angular template
import tmpls from './../view/templates';
/* eslint-enable */

const API_URL = {
  dev: 'https://api.test.livinglife.com.tw/',
  test: 'https://api.test.livinglife.com.tw/',
  beta: 'https://api.beta.livinglife.com.tw/',
  prod: 'https://api.livinglife.com.tw/'
}[__NODE_ENV__];

const APP_URL = {
  dev: 'http://localhost:3000/',
  test: 'https://admin.test.livinglife.com.tw/',
  beta: 'https://admin.beta.livinglife.com.tw/',
  prod: 'https://admin.livinglife.com.tw/'
}[__NODE_ENV__];

const WEBAPP_URL = {
  dev: 'https://test.livinglife.com.tw/',
  test: 'https://test.livinglife.com.tw/',
  beta: 'https://beta.livinglife.com.tw/',
  prod: 'https://livinglife.com.tw/'
}[__NODE_ENV__];

const DYNAMIC_LINK_URL = {
  dev: 'communityplus.page.link',
  dev2: 'communityplus.page.link',
  test: 'communityplus.page.link',
  test2: 'communityplus.page.link',
  beta: 'communityplus.page.link',
  stag: 'localbond.page.link',
  prod: 'localbond.page.link'
}[__NODE_ENV__];

const WEBSOCKET_URL = {
  dev: 'wss://q1896gedtc.execute-api.ap-northeast-1.amazonaws.com/test',
  test: 'wss://q1896gedtc.execute-api.ap-northeast-1.amazonaws.com/test',
  beta: 'wss://q1896gedtc.execute-api.ap-northeast-1.amazonaws.com/test',
  prod: 'wss://tzudjzgj4l.execute-api.ap-northeast-1.amazonaws.com/prod'
}[__NODE_ENV__];

// const INTERCOM_WORKSPACE = {
//   dev: 'd57n7yqz',
//   test: 'd57n7yqz',
//   beta: 'd57n7yqz',
//   prod: 'yx4fy94w'
// }[__NODE_ENV__];

const TIME_ZONE_OFFSET = 480;

const QuillImage = quill.import('formats/image');
QuillImage.className = 'c-edit-img';
quill.register(QuillImage, true);

angular.module('ckeditor', []).directive('ckeditor', ['$parse', 'Upload', 'API_URL', 'ywEvent', ngCkeditor]);

const ywApp = angular.module('ywApp', [
  angularAsyncAwait.name,
  'ngSanitize',
  'LocalStorageModule',
  'ui.router',
  'ui.bootstrap',
  'restangular',
  'ngFileUpload',
  'angular-loading-bar',
  'ngDialog',
  'ngMap',
  'ngAnimate',
  'dndLists',
  'mp.colorPicker',
  'gm.datepickerMultiSelect',
  'chart.js',
  'uiCropper',
  'ngclipboard',
  'ngMaterial',
  'ngMessages',
  'ngAria',
  'ngQuill',
  'ckeditor'
])
  .constant('API_URL', API_URL)
  .constant('APP_URL', APP_URL)
  .constant('WEBAPP_URL', WEBAPP_URL)
  .constant('WEBSOCKET_URL', WEBSOCKET_URL)
  // .constant('INTERCOM_WORKSPACE', INTERCOM_WORKSPACE)
  .constant('DYNAMIC_LINK_URL', DYNAMIC_LINK_URL)
  .constant('APP_VERSION', __APP_VERSION_NUM__)
  .constant('TIME_ZONE_OFFSET', TIME_ZONE_OFFSET)
  .config(routeConfig)
  .config(tokenRefreshInterceptorConfig)
  .config(restApiConfig)
  .config(compileProvider)
  .config(materialThemeConfig)
  .config(customNgQuillConfigProvider)

// factory
  .factory('tokenRefreshInterceptor', tokenRefreshInterceptor)
  .factory('reservationUtil', reservationUtil)
  .factory('unorderedReservationUtil', unorderedReservationUtil)
  .factory('newebpayDetailDialog', newebpayDetailDialog)
  .factory('articleUtil', articleUtil)
  .factory('albumUtil', albumUtil)
  .factory('venderUtil', venderUtil)
  .factory('filterItemFactory', filterItemFactory)
  .factory('serviceEditDialog', serviceEditDialog)
  .factory('imageUploadDialog', imageUploadDialog)
  .factory('labelManagement', labelManagement)
  .factory('timelineDialog', timelineDialog)
  .factory('session', session)
  .factory('ywUtil', ywUtil)
  .factory('currentUser', currentUser)
  .factory('address', address)
  .factory('paymentsSelectionDialog', paymentsSelectionDialog)
  .factory('ywDialog', ywDialog)
  .factory('ywEvent', ywEvent)
  .factory('ywPager', ywPager)
  .factory('ywTimezone', ywTimezone)
  .factory('WebSocket', WebSocket)
  .factory('confirmDialog', confirmDialog)
  .factory('popupSelectionDialog', popupSelectionDialog)
  .factory('serverVersion', serverVersion)
  .factory('mapUtil', mapUtil)
  .factory('uuid', uuid)
  .factory('sinyiStaffInfoUtil', sinyiStaffInfoUtil)
  .factory('layoutModuleUtils', layoutModuleUtils)
  .factory('orderUtil', orderUtil)
  .factory('activityDetailDialog', activityDetailDialog)
  .factory('searchableObjPickerDialog', searchableObjPickerDialog)
  .factory('multiSearchableObjPickerDialog', multiSearchableObjPickerDialog)
  .factory('multiObjPickerDialog', multiObjPickerDialog)
  .factory('shortUrl', shortUrl)
  .factory('firebaseAnalytics', firebaseAnalytics)
  .factory('checkboxDialog', checkboxDialog)
  .factory('subcatalogEditorDialog', subcatalogEditorDialog)

// life-factory
  .factory('lifeDialog', lifeDialog)
  .factory('lifePageUtil', lifePageUtil)
  .factory('lifeUnorderedUtil', lifeUnorderedUtil)

// service
  .factory('VenderStaff', VenderStaff)
  .factory('User', User)
  .factory('UserGroup', UserGroup)
  .factory('Vender', Vender)
  .factory('QualityTag', QualityTag)
  .factory('ServiceClassification', ServiceClassification)
  .factory('AdvancedFilterItem', AdvancedFilterItem)
  .factory('Account', Account)
  .factory('Service', Service)
  .factory('ServiceCapacity', ServiceCapacity)
  .factory('Order', Order)
  .factory('Reservation', Reservation)
  .factory('UnorderedReservation', UnorderedReservation)
  .factory('CouponEvent', CouponEvent)
  .factory('Coupon', Coupon)
  .factory('Quotation', Quotation)
  .factory('Banner', Banner)
  .factory('NotificationGroup', NotificationGroup)
  .factory('AdminNotifications', AdminNotifications)
  .factory('ArticleClassification', ArticleClassification)
  .factory('Article', Article)
  .factory('Keyword', Keyword)
  .factory('Catalog', Catalog)
  .factory('Album', Album)
  .factory('PhotoTag', PhotoTag)
  .factory('Photo', Photo)
  .factory('TA3Banner', TA3Banner)
  .factory('LayoutModule', LayoutModule)
  .factory('Layout', Layout)
  .factory('VenderContact', VenderContact)
  .factory('Bill', Bill)
  .factory('AccountTransactionDetail', AccountTransactionDetail)
// filter
  .filter('paymentMethod', paymentMethod)
  .filter('short-addr', shortAddr)
  .filter('user-role', userRole)
// directive
  .directive('lsEditor', lsEditor)
  .directive('ywPopupSelection', ywPopupSelection)
  .directive('ywPopupMultiSelection', ywPopupMultiSelection)
  .directive('searchableObjPicker', searchableObjPicker)
  .directive('multiSearchableObjPicker', multiSearchableObjPicker)
  .directive('ywUpload', ywUpload)
  .directive('ywDatePickerInput', ywDatePickerInput)
  .directive('ywTimePickerInput', ywTimePickerInput)
  .directive('timelineInput', timelineInput)
  .directive('serviceCapacitySetting', serviceCapacitySetting)
  .directive('couponEventDetail', couponEventDetail)
  .directive('ywProductCustomization', ywProductCustomization)
  .directive('customizationItems', customizationItems)
  .directive('webviewManagement', webviewManagement)
  .directive('cancelledWordManagement', cancelledWordManagement)
  .directive('ywDropzone', ywDropzone)
  .directive('colorSelection', colorSelection)
  .directive('tabWatcher', tabWatcher)

// non used directive
  .directive('dayPicker', dayPicker)
  .directive('serviceTimePicker', serviceTimePicker)
  .directive('ywUserSelector', ywUserSelector)
  .directive('singleCoordinateInput', singleCoordinateInput)
  .directive('ywTagSetting', ywTagSetting)
  .directive('dropdownSelection', dropdownSelection)
  .directive('ywTimePeriodValuePreview', ywTimePeriodValuePreview)
  .directive('pushMessage', pushMessage)
  .directive('productOrderSetupButton', productOrderSetupButton)
  .directive('imgCropAndUpload', imgCropAndUpload)
  .directive('sipNumberRegister', sipNumberRegister)
  .directive('navigationSetting', navigationSetting)
  .directive('imagePreviewDialog', imagePreviewDialog)
  .directive('ywSwitch', ywSwitch)
  .directive('textWithEditDialog', textWithEditDialog)
  .directive('urlShorter', urlShorter)
  .directive('dynamicLinkGenerater', dynamicLinkGenerater)
  .directive('timePeriodInWeek', timePeriodInWeek)
  .directive('timepartSetting', timepartSetting)
  .directive('selectTimeInWeek', selectTimeInWeek)
  .directive('timepartPicker', timepartPicker)
  .directive('ywLoaded', ywLoaded)
  .directive('ngCkeditor', ngCkeditor)

// component
  .component('iconButton', iconButton)
  .component('tableCheckbox', tableCheckbox)
  .component('tableImgPreview', tableImgPreview)
  .component('imageViewer', imageViewer)
  .component('labelManagementTable', labelManagementTable)
  .component('websiteContentPage', websiteContentPage)
  .component('reservationUtmInfo', reservationUtmInfo)
  .component('reservationSinyiStaffInfo', reservationSinyiStaffInfo)
  .component('reservationServiceInfo', reservationServiceInfo)
  .component('reservationUserImage', reservationUserImage)
  .component('reservationVenderProcessingImage', reservationVenderProcessingImage)
  .component('reservationVenderMemoImage', reservationVenderMemoImage)
  .component('reservationNote', reservationNote)
  .component('unorderedReservationServiceInfo', unorderedReservationServiceInfo)
  .component('reservationSinyiStaffEditIcon', reservationSinyiStaffEditIcon)
  .component('reservationSelfConstructButton', reservationSelfConstructButton)
  .component('reservationReferralFeeInfoButton', reservationReferralFeeInfoButton)
  .component('reservationReferralFeeUpdateButton', reservationReferralFeeUpdateButton)
  .component('reservationVisibleButton', reservationVisibleButton)
  .component('reservationNotifyButton', reservationNotifyButton)
  .component('reservationTerminateFeeButton', reservationTerminateFeeButton)
  .component('reservationGuidePurchase', reservationGuidePurchase)
  .component('reservationVatInfo', reservationVatInfo)
  .component('lifeButton', lifeButton)
  .component('lifeDetailMenu', lifeDetailMenu)
  .component('lifeFooter', lifeFooter)
  .component('lifeTab', lifeTab)
  .component('lifeTag', lifeTag)
  .component('lifeStatusButton', lifeStatusButton)
  .component('lifeInput', lifeInput)
  .component('lifeLabelInput', lifeLabelInput)
  .component('lifeTextarea', lifeTextarea)
  .component('lifeRadio', lifeRadio)
  .component('lifeDialogFooter', lifeDialogFooter)
  .component('lifeSelect', lifeSelect)
  .component('lifeCheck', lifeCheck)
  .component('lifeCalendar', lifeCalendar)
  .component('lifeDatePicker', lifeDatePicker)
  .component('lifeToast', lifeToast)
// react component
  .component(
    'reservationListComponent',
    react2angular(
      ReservationListComponent,
      [],
      ['ywEvent', 'TIME_ZONE_OFFSET', 'currentUser']
    )
  )
  .component(
    'unorderedReservationListComponent',
    react2angular(
      UnorderedReservationListComponent,
      [],
      ['ywEvent', 'TIME_ZONE_OFFSET', 'currentUser'])
  )

// controller
  .controller('mainController', mainCtrl)
  .controller('mainActionController', mainActionCtrl)
  .controller('LeftNavController', leftNavCtrl)
  .controller('HeaderController', headerCtrl)
  .controller('loginController', loginCtrl)
  .controller('baseListController', baseListCtrl)
  .controller('baseActionController', baseActionCtrl)
// admin controllers
  .controller('adminController', adminCtrl)
  .controller('serviceAccountController', serviceAccountController)
// service admin controllers
  .controller('serviceAdminController', serviceAdminController)
  .controller('saVenderController', saVenderController)
  .controller('saVenderListController', saVenderListController)
  .controller('saVenderActionController', saVenderActionController)
  .controller('saVenderQualityTagController', saVenderQualityTagController)
  .controller('saVenderQualityTagListController', saVenderQualityTagListController)
  .controller('saVenderQualityTagActionController', saVenderQualityTagActionController)
  .controller('saServiceClassificationController', saServiceClassificationController)
  .controller('saServiceClassificationListController', saServiceClassificationListController)
  .controller('saServiceClassificationActionController', saServiceClassificationActionController)
  .controller('saAdvancedFilterItemController', saAdvancedFilterItemController)
  .controller('saAdvancedFilterItemListController', saAdvancedFilterItemListController)
  .controller('saAdvancedFilterItemActionController', saAdvancedFilterItemActionController)
  .controller('saVenderAccountController', saVenderAccountController)
  .controller('saVenderAccountListController', saVenderAccountListController)
  .controller('saVenderAccountActionController', saVenderAccountActionController)
  .controller('saVenderAdvancedFilterItemController', saVenderAdvancedFilterItemController)
  .controller('saServiceController', saServiceController)
  .controller('saServiceListController', saServiceListController)
  .controller('saServiceActionController', saServiceActionController)
  .controller('saBillController', saBillController)
  .controller('saBillListController', saBillListController)
  .controller('saAccountController', saAccountController)
  .controller('saAccountListController', saAccountListController)
  .controller('saAccountTransactionDetailController', saAccountTransactionDetailController)
  .controller('saAccountTransactionDetailListController', saAccountTransactionDetailListController)
  .controller('saReservationController', saReservationController)
  .controller('saReservationListController', saReservationListController)
  .controller('saUnorderedReservationController', saUnorderedReservationController)
  .controller('saUnorderedReservationListController', saUnorderedReservationListController)
  .controller('saUnorderedReservationQuotationController', saUnorderedReservationQuotationController)
  .controller('saReservationQuotationController', saReservationQuotationController)
  .controller('saReservationQuotationListController', saReservationQuotationListController)
  .controller('saReservationQuotationActionController', saReservationQuotationActionController)
  .controller('saCouponEventController', saCouponEventController)
  .controller('saCouponEventListController', saCouponEventListController)
  .controller('saCouponController', saCouponController)
  .controller('saCouponListController', saCouponListController)
  .controller('saSystemSettingController', saSystemSettingController)
  .controller('saBannerController', saBannerController)
  .controller('saBannerListController', saBannerListController)
  .controller('saTA3BannerActionController', saTA3BannerActionController)
  .controller('saTA3BannerController', saTA3BannerController)
  .controller('saTA3BannerListController', saTA3BannerListController)
  .controller('saBannerActionController', saBannerActionController)
  .controller('saNotificationController', saNotificationController)
  .controller('saNotificationListController', saNotificationListController)
  .controller('saNotificationActionController', saNotificationActionController)
  .controller('saCustomerController', saCustomerController)
  .controller('saCustomerListController', saCustomerListController)
  .controller('saCustomerActionController', saCustomerActionController)
  .controller('saCustomerGroupController', saCustomerGroupController)
  .controller('saCustomerGroupListController', saCustomerGroupListController)
  .controller('saCustomerGroupActionController', saCustomerGroupActionController)
  .controller('saArticleClassificationController', saArticleClassificationController)
  .controller('saArticleClassificationListController', saArticleClassificationListController)
  .controller('saArticleClassificationActionController', saArticleClassificationActionController)
  .controller('saArticleController', saArticleController)
  .controller('saArticleListController', saArticleListController)
  .controller('saArticleActionController', saArticleActionController)
  .controller('saKeywordController', saKeywordController)
  .controller('saKeywordListController', saKeywordListController)
  .controller('saKeywordActionController', saKeywordActionController)
  .controller('saWebsiteContentLayoutController', saWebsiteContentLayoutController)
  .controller('saWebsiteContentAlbumController', saWebsiteContentAlbumController)
  .controller('saCatalogController', saCatalogController)
  .controller('saCatalogListController', saCatalogListController)
  .controller('saCatalogActionController', saCatalogActionController)
  .controller('saAlbumController', saAlbumController)
  .controller('saAlbumListController', saAlbumListController)
  .controller('saAlbumActionController', saAlbumActionController)
  .controller('saPhotoTagController', saPhotoTagController)
  .controller('saPhotoTagListController', saPhotoTagListController)
  .controller('saPhotoTagActionController', saPhotoTagActionController)
  .controller('saPhotoController', saPhotoController)
  .controller('saPhotoListController', saPhotoListController)
  .controller('saPhotoActionController', saPhotoActionController)
  .controller('saLayoutModuleController', saLayoutModuleController)
  .controller('saLayoutModuleListController', saLayoutModuleListController)
  .controller('saLayoutController', saLayoutController)
  .controller('saLayoutListController', saLayoutListController)
  .controller('saLayoutActionController', saLayoutActionController)
// vender controllers
  .controller('venderController', venderController)
  .controller('venderVenderStaffController', venderVenderStaffController)
  .controller('venderVenderStaffListController', venderVenderStaffListController)
  .controller('venderVenderStaffActionController', venderVenderStaffActionController)
  .controller('venderAccountSettingController', venderAccountSettingController)
  .controller('venderAccountSettingActionController', venderAccountSettingActionController)
  // life controllers
  .controller('reservationDetailController', reservationDetailController)
  .controller('unorderedReservationDetailController', unorderedReservationDetailController);

ywApp.run(['$rootScope', '$location', '$state', '$http', 'Restangular', 'API_URL', 'currentUser', 'session', 'serverVersion',
  function run ($rootScope, $location, $state, $http, Restangular, API_URL, currentUser, session, serverVersion) {
    // this can not be done by app.config()
    // Restangular.setBaseUrl($location.protocol() + ':' + API_URL);
    Restangular.setBaseUrl(API_URL);

    currentUser.initial();

    const getMeOption = {
      method: 'GET',
      url: API_URL + 'users/me'
    };
    // access_token寫在queryString僅用在測試，APP會使用window.ywData.token
    const tokenFromWebview = getParameterByName('access_token') || _.get(window, 'ywData.token');
    if (tokenFromWebview) {
      currentUser.setAuthToken(tokenFromWebview);
      getMeOption.headers = { authorization: `Bearer ${tokenFromWebview}` };
    }

    // fetch refresh token if none
    if (session._id && !session.refreshToken) {
      $http({
        method: 'GET',
        url: `${API_URL}users/myRefreshToken`
      }).then(function (res) {
        if (!_.get(res, 'data.error')) {
          session.set(_.get(res, 'data.result'));
        }
      });
    }

    $http(getMeOption).then(function (res) {
      if (_.get(res, 'data.error.code') === 401) {
        throw new Error('auth_error');
      }

      serverVersion.set(res.headers('X-Version'));

      const data = res.data;
      const currentSetObj = { user: _.get(data, 'result.me') };
      if (tokenFromWebview) currentSetObj.token = tokenFromWebview;
      if (data.result && data.ok) { currentUser.setInfo(currentSetObj); }

      $rootScope.isLoggedIn = true;

      if ($location.path() === '/') { $state.go(currentUser.getInitialState()); }
    }).catch(function (res) {
      if (typeof res.headers === 'function') {
        serverVersion.set(res.headers('X-Version'));
      }
      if (res.status === 401 || res.message === 'auth_error') {
        if ($state.current.url.split('/')[1] === 'reservation-detail') {
          localStorage.setItem('reservationNo', $state.params.reservationNo); // eslint-disable-line
        }
        $state.go('login');
      }
    });
  }]);

function getParameterByName (name, url) {
  if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&'); // eslint-disable-line
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
