import moment from 'moment';

export default ['$scope', '$async', 'address', 'Restangular', 'ServiceCapacity', 'ServiceClassification', 'Vender', 'confirmDialog', 'VenderContact', 'layoutModuleUtils', 'localStorageService', 'ywUtil', 'ywEvent', 'QualityTag',
  function controller ($scope, $async, address, Restangular, ServiceCapacity, ServiceClassification, Vender, confirmDialog, VenderContact, layoutModuleUtils, localStorageService, ywUtil, ywEvent, QualityTag) {
    const self = this;
    $scope.customizeActionContentTmpl = '/view/page/admin/tabset-action-page-with-tab-watcher.html';

    self.uploaded = $scope.imageUploaded;
    self.singleFileUploaded = $scope.singleFileUploaded;
    $scope.inputItems = [];
    $scope.transform = transform;
    $scope.mainAction._validator = validator;
    $scope.introLogoFile = {};
    $scope.introTypicalImageFile = {};
    $scope.introTypicalMobileImageFile = {};
    $scope.tabIndex = 0;
    let hasCreatedVendor = $scope.currentPageState !== 'add';
    const vendorInfoParams = ywUtil.getVendorInfoParams;
    $scope.tabClicked = function (index) {
      $scope.tabIndex = index;
    };

    $scope.$watch('currentActionItem', syncCurrentActionItem);

    const save = async () => {
      try {
        const saveFields = {};
        vendorInfoParams[$scope.tabIndex].forEach(param => {
          _.set(saveFields, param, $scope.currentActionItem[param]);
        });

        if ($scope.tabIndex === 0 && !hasCreatedVendor) {
          const res = await $scope.$parent.save({ doNothingAfterSave: false, isOnlyExtraParams: true, extraParams: saveFields, isShowPrevPage: false });
          if (res.error) {
            throw new Error(res.error);
          }
          hasCreatedVendor = true;
          $scope.currentActionItem = res;
          $scope.currentPageState = 'edit';
        } else {
          await update();
        }
      } catch (error) {
        $scope.alert(error.message);
        $scope.status.isLoading = false;
      }
    };

    $scope.save = save;

    const update = async () => {
      try {
        const updateFields = {};
        vendorInfoParams[$scope.tabIndex].forEach(param => {
          _.set(updateFields, param, $scope.currentActionItem[param]);
        });
        const res = await $scope.$parent.update({ doNothingAfterUpdate: false, updateFields, apiCategory: 'venders', isShowPrevPage: false });

        if ($scope.currentActionItem._notServiceInModified) {
          const notServiceIn = $scope.currentActionItem.notServiceIn.map(date => moment(date).toISOString());
          await Vender.one(res._id).customPUT({ notServiceIn }, 'notServiceIn');
        }

        if (res.error) {
          throw new Error(res.error);
        }

        if ($scope.tabIndex === 1) {
          $scope.status.isLoading = true;
          await updateContacts(res);
          const serviceCapacityDataList = $scope.currentActionItem?._serviceCapacities || [];
          await updateServiceCapacities(res, serviceCapacityDataList);

          await ServiceCapacity.one(res._id).customPOST({}, 'syncCapacity');

          // 找出要刪除的聯絡人
          const orginContactIds = ($scope?.orginContacts || []).map(contact => contact._id);
          const newContactIds = ($scope?.newContacts || []).map(contact => contact._id);
          const removedContactIds = _.difference(orginContactIds, newContactIds);

          // 刪除聯絡人
          const removedContactsRes = await Promise.all(removedContactIds.map((id) => VenderContact.one(id).remove()));
          let cannotDeletedContacts = '';
          removedContactsRes.forEach(res => {
            if (res.error) {
              if (res?.errorSource?.debugInfo?.venderContactName) {
                cannotDeletedContacts += ((cannotDeletedContacts ? '、' : '') + res.errorSource.debugInfo.venderContactName);
              } else {
                throw new Error(res.error);
              }
            }
          });

          if (cannotDeletedContacts) {
            $scope.alert(cannotDeletedContacts + '目前使用中，無法刪除');
          }

          const updatedRes = await Vender.one(res._id).customGET();
          // $scope.currentActionItem = updatedRes;
          $scope.afterUpdate({ res: updatedRes, isShowPrevPage: false });
        }
        // else {
        //   $scope.currentActionItem = res;
        // }
      } catch (error) {
        console.error('Error in update:', error);
        $scope.alert(error.message || '發生錯誤');
      } finally {
        $scope.status.isLoading = false;
      }
    };

    $scope.update = update;

    // 在 update() 內呼叫
    const updateServiceCapacities = async (vender, capacities) => {
      console.log('data list: ', capacities);
      // 將 ID 取出來
      for (const capacity of capacities) {
        capacity.services = capacity._services.map(service => service._id);
        capacity.contacts = capacity._contacts.map(contact => {
          if (contact._id) {
            return contact._id;
          } else {
            return $scope.contactMap[contact._uuid];
          }
        });
      }

      const newCapacities = await Promise.all(capacities.map(capacity => capacity._id
        ? ServiceCapacity.one(capacity._id).customPUT(capacity)
        : ServiceCapacity.post({ ...capacity, venderId: vender._id })));

      // 有錯就中斷，避免capacity被錯誤刪除
      const errorResult = _.find(newCapacities, capacity => capacity.error);
      if (errorResult) {
        throw new Error(errorResult.error);
      }

      // load origin capacity ids
      const originCapacities = await ServiceCapacity.getList({
        venderId: vender._id,
        limit: 999
      });

      // remove unused service capacities
      const originCapacityIds = originCapacities.map(capacity => capacity._id);
      const newCapacityIds = newCapacities.map(capacity => capacity._id);
      const removedIds = _.difference(originCapacityIds, newCapacityIds);
      await Promise.all(removedIds.map((id) => ServiceCapacity.one(id).remove()));

      return newCapacities;
    };

    // 在 update() 內呼叫
    const updateContacts = async (vender) => {
      try {
        // 新增、更新聯絡人
        const currentContactsList = $scope.currentActionItem?._venderContactsList || [];
        $scope.newContacts = await Promise.all(
          currentContactsList.map(contact =>
            contact._id
              ? VenderContact.one(contact._id).customPUT(contact)
              : VenderContact.post({ ...contact, vender: vender._id })
          )
        );

        // 比對原本自行設定的uuid和後端建立的id
        $scope.contactMap = {};
        currentContactsList.forEach((contact, index) => {
          if (contact._uuid) $scope.contactMap[contact._uuid] = $scope.newContacts[index]._id;
        });

        // 有錯就中斷
        const errorResult = _.find($scope.newContacts, contact => contact.error);
        if (errorResult) {
          throw new Error(errorResult.error);
        }
      } catch (error) {
        throw new Error(error.message || 'Error in updateContacts');
      }
    };

    function validator () {
      const item = $scope.currentActionItem;
      const isEdit = $scope.currentPageState === 'edit';
      console.log('validator:', item);
      switch ($scope.tabIndex) {
        case 0: // 基本設定
          if (!item.name) throw new Error('公司名稱不能為空');
          if (!item.vatNo) throw new Error('統編不能為空');
          if (!/^\d{8}$/.test(item.vatNo)) throw new Error('統編須為8碼半型數字');
          // if (!/^\d{8,10}$/.test(item.phone)) throw new Error('聯絡電話須為8-10位數字');
          if (!_.get(item, 'owner.email')) throw new Error('負責人email不能為空');
          if (isEdit && !item.urlId) throw new Error('網址辨識名稱不能為空');
          if (item.urlId) {
            if (!/^[a-z-0-9_]+$/.test(item.urlId)) throw new Error('網址辨識名稱格式不符');
          }
          for (const contact of item.orderContacts) {
            if (contact.phone) {
              const cleanedPhone = contact.phone.replace(/-/g, '');

              if (!contact.period) {
                throw new Error('訂單聯繫電話-時段資訊必須填寫');
              }

              if (contact.phone.length > 12) {
                throw new Error('電話號碼的長度不能超過 12 位');
              }

              if (!/^\d{9,10}$/.test(cleanedPhone)) {
                throw new Error('當填寫電話時，電話號碼應為 9 到 10 位數字');
              }
            }
          }
          return true;
        case 1: // 服務方案與派案
          if (_.get(item, '_serviceCapacities.length')) {
            if (!item._serviceCapacities.every(capacity => _.get(capacity, '_contacts.length'))) {
              throw new Error('服務方案聯絡人不能為空');
            }
          }
          return true;
        case 3: // 介紹資訊
          if (_.get(item, '_introductionInfoServiceIntroduction.length')) {
            if (item._introductionInfoServiceIntroduction.some(intro => !intro.image || !intro.title || !intro.description)) {
              throw new Error('服務介紹圖示、標題、說明皆為必填');
            }
          }
          if (_.get(item, '_introductionInfoUrls.length')) {
            item._introductionInfoUrls.forEach(intro => {
              if (!intro.name || !intro.url) {
                throw new Error('名稱、網址皆為必填');
              }
              if (intro.name.length > 15) {
                throw new Error('名稱最多 15 個字');
              }
              const urlPattern = /^(http:\/\/|https:\/\/)/;
              if (!urlPattern.test(intro.url)) {
                throw new Error('網址必須以 http 或 https 開頭');
              }
              if (intro.url.length > 2000) {
                throw new Error('網址長度不得超過 2000 字');
              }
            });
          }
          if (_.get(item, '_introductionInfoMedalOfHonor.length')) {
            if (item._introductionInfoMedalOfHonor.some(info => !info.image || !info.time || !info.award)) {
              throw new Error('榮譽勳章勳章圖示、時間、獎項皆為必填');
            }
          }
          if (!_.isEmpty(item._introductionInfoVoIP)) {
            const cleanedVoIP = item._introductionInfoVoIP.replace(/-/g, '');

            if (!/^\d+$/.test(cleanedVoIP)) {
              throw new Error('電話須為數字');
            }

            if (cleanedVoIP.length < 9 || cleanedVoIP.length > 10) {
              throw new Error('電話長度須為9至10位數');
            }

            if (item._introductionInfoVoIP.length > 12) {
              throw new Error('電話最多只能輸入12字');
            }
          }

          if (!_.isEmpty(item._introductionInfoExtension)) {
            if (!/^\d+$/.test(item._introductionInfoExtension)) {
              throw new Error('電話分機須為數字');
            }

            if (item._introductionInfoExtension.length > 20) {
              throw new Error('電話分機最多只能輸入20字');
            }
          }

          if (!_.isNumber(item.customerBudget)) {
            throw new Error('介紹資訊的接案預算門檻為必填');
          }
          return true;
        default:
          return true;
      }
    }

    function transform () {
      const item = $scope.currentActionItem;
      if (item._addressDetails) {
        _.set(item, 'address.details', item._addressDetails);
      }

      [
        { src: '_ownerName', dest: 'name' },
        { src: '_ownerTwId', dest: 'twid' },
        { src: '_ownerPhone', dest: 'phone' },
        { src: '_ownerEmail', dest: 'email' }
      ].forEach(key => item[key.src] && _.set(item, `owner.${key.dest}`, item[key.src]));
      item.accountReceiptMails = item?.accountReceiptMails?.filter(text => text) || [];

      [
        { src: '_contractNo', dest: 'no' },
        { src: '_contractFrom', dest: 'from' },
        { src: '_contractTo', dest: 'to' }
      ].forEach(key => item[key.src] && _.set(item, `contract.${key.dest}`, item[key.src]));

      item.spgateway = {
        merchantId: item._spgatewayMerchantId || '',
        hashKey: item._spgatewayHashKey || '',
        hashIV: item._spgatewayHashIV || ''
      };
      item.spgatewayFor3DVerification = {
        merchantId: item._spgatewayFor3DVerificationMerchantId || '',
        hashKey: item._spgatewayFor3DVerificationHashKey || '',
        hashIV: item._spgatewayFor3DVerificationHashIV || ''
      };

      item.bankAccount = {
        bankName: item._bankAccountBankName || '',
        branchName: item._bankAccountBranchName || '',
        code: item._bankAccountCode || '',
        accountName: item._bankAccountAccountName || '',
        no: item._bankAccountNo || ''
      };

      item.acceptanceSetting = {
        isHiddenTotal: item._acceptanceSettingIsHiddenTotal || false,
        isHiddenPhone: item._acceptanceSettingIsHiddenPhone || false
      };

      item.isActiveSmsSending = $scope.isActiveSmsSendingCheckbox.checked;
      // item.isShowRecordHint = $scope.isShowHintCheckbox.checked;

      item.orderContacts = _.get(item, 'orderContacts', []).map(contact => {
        return {
          period: contact.period,
          phone: contact.phone,
          extension: contact.extension
        };
      });

      item.isDisplayGotoReservation = item._isDisplayGotoReservation;
      item.gotoReservationDefaultName = item._gotoReservationDefaultName;
      item.qualityTags = (item._qualityTags || []).map(tag => tag._id);
      item.serviceItems = (item._serviceItems || []).map(service => service._id);
      item.introductionInfo = {
        isVisible: item._introductionInfoIsVisible,
        isFilterListVisible: item._introductionInfoIsFilterListVisible,
        isRecommendedMarkVisible: item._introductionInfoIsRecommendedMarkVisible,
        announcement: item._introductionInfoAnnouncement,
        favorableDiscount: item._introductionInfoFavorableDiscount,
        serviceCounties: item._introductionInfoServiceCounties,
        serviceItems: item._introductionServiceItems,
        serviceIntroduction: _.get(item, '_introductionInfoServiceIntroduction', []).map(intro => ({
          image: _.get(intro, 'image._id'),
          title: intro.title,
          description: intro.description
        })),
        urls: _.get(item, '_introductionInfoUrls', []).map(url => ({
          name: url.name,
          url: url.url
        })),
        voIP: item._introductionInfoVoIP,
        extension: item._introductionInfoExtension,
        medalOfHonor: _.get(item, '_introductionInfoMedalOfHonor', []).map(info => ({
          image: _.get(info, 'image._id'),
          time: info.time,
          award: info.award
        })),
        brieflyDescribe: item._introductionInfoBrieflyDescribe,
        venderIntroduction: _.get(item, '_introductionInfoVenderIntroduction', []).map(intro => intro._id) || [],
        designerIntroduction: item._introductionInfoDesignerIntroduction || ''
      };
      if (item._introductionInfoLogo) {
        item.introductionInfo.logo = item._introductionInfoLogo;
      }
      if (item._introductionInfoTypicalImage) {
        item.introductionInfo.typicalImage = item._introductionInfoTypicalImage;
      }
      if (item._introductionInfoTypicalMobileImage) {
        item.introductionInfo.typicalMobileImage = item._introductionInfoTypicalMobileImage;
      }
      console.log('transform finished: ', $scope.currentActionItem);
    }

    async function syncCurrentActionItem () {
      $scope.status.isLoading = true;
      const item = $scope.currentActionItem;
      $scope.moduleTypes = [];
      $scope.moduleTypesItems = [];
      await layoutModuleUtils.getLayoutModuleTypes($scope);
      item._qualityTagsList = (await QualityTag.getList({ limit: 999 }));

      item._serviceItemsList = await ServiceClassification.getList({ limit: 999, displayVisible: 'all' });
      if ($scope.currentPageState === 'edit') {
        const vender = await Vender.one(item._id).customGET();
        const venderAdvancedFilters = await Restangular.service('venderAdvancedFilters').getList({ limit: 500, vender: item._id });
        item._spgatewayMerchantId = _.get(item, 'spgateway.merchantId');
        item._spgatewayHashKey = _.get(item, 'spgateway.hashKey');
        item._spgatewayHashIV = _.get(item, 'spgateway.hashIV');
        item._spgatewayFor3DVerificationMerchantId = _.get(item, 'spgatewayFor3DVerification.merchantId');
        item._spgatewayFor3DVerificationHashKey = _.get(item, 'spgatewayFor3DVerification.hashKey');
        item._spgatewayFor3DVerificationHashIV = _.get(item, 'spgatewayFor3DVerification.hashIV');

        item._bankAccountBankName = _.get(item, 'bankAccount.bankName');
        item._bankAccountBranchName = _.get(item, 'bankAccount.branchName');
        item._bankAccountCode = _.get(item, 'bankAccount.code');
        item._bankAccountAccountName = _.get(item, 'bankAccount.accountName');
        item._bankAccountNo = _.get(item, 'bankAccount.no');

        // item._serviceCapacities = _.get(vender, 'ownServices', []);
        // ywEvent.emit('INIT_SERVICE_CAPACITY_SETTING');

        item.orderContacts = _.get(item, 'orderContacts', [
          { period: '平日(週一到週五)', phone: '', extension: '' },
          { period: '週末(含國定假日)', phone: '', extension: '' }
        ]);

        item._acceptanceSettingIsHiddenTotal = _.get(item, 'acceptanceSetting.isHiddenTotal', false);
        item._acceptanceSettingIsHiddenPhone = _.get(item, 'acceptanceSetting.isHiddenPhone', false);
        item._qualityTags = _.get(vender, 'ownQualityTag.qualityTags', []);
        item._serviceItems = venderAdvancedFilters.map(filter => filter.serviceClassification);
        item._introductionInfoIsVisible = _.get(item, 'introductionInfo.isVisible');
        item._introductionInfoIsFilterListVisible = _.get(item, 'introductionInfo.isFilterListVisible');
        item._introductionInfoIsRecommendedMarkVisible = _.get(item, 'introductionInfo.isRecommendedMarkVisible');
        item._isDisplayGotoReservation = _.get(item, 'isDisplayGotoReservation');
        item._gotoReservationDefaultName = _.get(item, 'gotoReservationDefaultName');
        item._introductionInfoAnnouncement = _.get(item, 'introductionInfo.announcement');
        item._introductionInfoFavorableDiscount = _.get(item, 'introductionInfo.favorableDiscount');
        item._introductionInfoLogoUrl = _.get(vender, 'introductionInfo.logo');
        item._introductionInfoLogo = _.get(vender, 'introductionInfo.logo._id');
        item._introductionInfoTypicalImageUrl = _.get(vender, 'introductionInfo.typicalImage');

        item._introductionInfoTypicalImage = _.get(vender, 'introductionInfo.typicalImage._id');
        item._introductionInfoTypicalMobileImageUrl = _.get(vender, 'introductionInfo.typicalMobileImage');
        item._introductionInfoTypicalMobileImage = _.get(vender, 'introductionInfo.typicalMobileImage._id');
        item._introductionInfoServiceCounties = _.get(item, 'introductionInfo.serviceCounties', []);
        item._introductionInfoServiceIntroduction = _.get(vender, 'introductionInfo.serviceIntroduction', []);
        item._introductionInfoUrls = _.get(vender, 'introductionInfo.urls', []);
        item._introductionInfoVoIP = _.get(item, 'introductionInfo.voIP');
        item._introductionInfoMedalOfHonor = _.get(vender, 'introductionInfo.medalOfHonor', []);
        item._introductionInfoExtension = _.get(item, 'introductionInfo.extension');
        item._introductionInfoBrieflyDescribe = _.get(item, 'introductionInfo.brieflyDescribe');
        item._introductionInfoDesignerIntroduction = _.get(item, 'introductionInfo.designerIntroduction');
        item._introductionInfoVenderIntroduction = _.get(vender, 'introductionInfo.venderIntroduction', []).map(module => {
          return {
            ...module,
            typeName: layoutModuleUtils.getLayoutModuleTypeName(module.type)
          };
        });

        // item.isShowRecordHint = _.get(item, 'isShowRecordHint');

        item.labels = _.get(item, 'labels', []).map(label => label._id);

        item._venderContactsList = await VenderContact.getList({
          venderId: item._id,
          limit: 999
        });
        item._venderContactsList = item._venderContactsList.map(contact => {
          const serviceMap = {};
          contact?.services.forEach(service => {
            if (!_.isArray(serviceMap[service?.classification?._id])) {
              serviceMap[service?.classification?._id] = [];
            }
            serviceMap[service?.classification?._id].push(service);
          });

          let tips = '';
          contact?.serviceClassifications.forEach((serviceClassification, index) => {
            tips += (index === 0 ? '聯絡人被設定使用方案<br>' : '');
            tips += `${index + 1}.${serviceClassification.name}`;
            if (serviceMap[serviceClassification._id]?.length > 0) {
              serviceMap[serviceClassification._id].forEach((service, index) => {
                tips += (index === 0 ? '-' : '、');
                tips += service.name;
              });
            }
            tips += '<br>';
          });

          return {
            ...contact,
            info: tips
          };
        });
        $scope.orginContacts = _.cloneDeep(item._venderContactsList);
      }

      /* 顯示電話錄音提示 */
      // $scope.isShowHintCheckbox = {
      //   value: false,
      //   label: '顯示電話錄音提示',
      //   checked: item.isShowRecordHint
      // };

      /* address settings */
      $scope.districtInput = {
        label: '地區',
        type: 'dropdown',
        selected: _.get($scope, 'currentActionItem.address.district'),
        options: [],
        changed: (value) => {
          _.set($scope.currentActionItem, 'address.district', value || '');
        }
      };
      $scope.syncDistrictOptions = function (county) {
        $scope.districtInput.options = address[county];
      };
      if (_.hasIn($scope, 'currentActionItem.address.county')) { $scope.syncDistrictOptions($scope.currentActionItem.address.county); }

      if ($scope.currentPageState === 'edit') {
        item._contractNo = _.get(item, 'contract.no', '');
        item._contractFrom = _.get(item, 'contract.from');
        item._contractTo = _.get(item, 'contract.to');
        item._addressDetails = _.get(item, 'address.details', '');
        item._ownerName = _.get(item, 'owner.name', '');
        item._ownerTwId = _.get(item, 'owner.twid', '');
        item._ownerPhone = _.get(item, 'owner.phone', '');
        item._ownerEmail = _.get(item, 'owner.email', '');

        item.notServiceIn = _.get(item, 'notServiceIn', [])
          .map(date => moment(date).valueOf())
          .filter(date => moment(date).isSameOrAfter(moment().startOf('date')));
      }

      $scope.isActiveSmsSendingCheckbox = {
        value: true,
        label: '允許廠商使用簡訊對用戶發送訊息通知',
        checked: item.isActiveSmsSending
      };

      // 廠商介紹模組
      $scope.contentManagement = {
        label: '廠商介紹',
        type: 'custom-content',
        searchBarOptions: [
          { btnName: '模組名稱', placeholder: '請輸入模組名稱', params: 'name' },
          { btnName: '模組ID', placeholder: '請輸入模組ID', params: 'moduleId' }
        ],
        searchBarOptionsActive: { btnName: '模組名稱', placeholder: '請輸入模組名稱', params: 'name' },
        customSearchText: '',
        changeSearchBarActiveOption,
        moduleType: {
          options: $scope.moduleTypes
        },
        articleModuleArray: _.get(item, '_introductionInfoVenderIntroduction', []),
        itemMoved: (index) => $scope.contentManagement.articleModuleArray.splice(index, 1),
        itemRemoved: (index) => $scope.contentManagement.articleModuleArray.splice(index, 1),
        addModule,
        clear,
        onEditClick,
        onCopyIdClick
      };

      function changeSearchBarActiveOption (currentOption, newOption) {
        $scope.contentManagement.searchBarOptionsActive = newOption;
      }

      async function addModule () {
        function seletAll (isChecked, arr) {
          arr.forEach(item => {
            item.select = isChecked;
          });
        }
        const data = {};
        try {
          $scope.status.isLoading = true;
          data.layoutModuleList = await Restangular.one('layoutModules').get({
            type: $scope.contentManagement.moduleType.select,
            name: $scope.contentManagement.searchBarOptionsActive.params === 'name' ? $scope.contentManagement.customSearchText : undefined,
            moduleId: $scope.contentManagement.searchBarOptionsActive.params === 'moduleId' ? $scope.contentManagement.customSearchText : undefined
          });
          const articleUsedModule = _.isEmpty($scope.contentManagement.articleModuleArray) ? [] : $scope.contentManagement.articleModuleArray.map(articleModule => { return articleModule._id; });
          data.layoutModuleList = _.isEmpty(articleUsedModule) ? data.layoutModuleList : data.layoutModuleList.filter(layoutModule => !articleUsedModule.some((item) => item === layoutModule?._id));
          data.layoutModuleList = data.layoutModuleList.map(module => { return { ...module, typeName: layoutModuleUtils.getLayoutModuleTypeName(module.type) }; });
        } catch (error) {
          console.log('ALERT', error);
          $scope.status.isLoading = false;
          return;
        } finally {
          $scope.status.isLoading = false;
        }
        if (_.isArray(data.layoutModuleList) && data.layoutModuleList.length === 0) return $scope.alert('找不到模組');
        confirmDialog.openConfirm({
          title: '請選擇需要加入頁面的模組',
          templateUrl: '/view/dialog/layout-module-selector.html',
          data,
          seletAll
        }, 'custom-width-800').then(() => {
          data.layoutModuleList.forEach(item => {
            if (item.select) $scope.contentManagement.articleModuleArray.push(item);
          });
        });
      }

      function onEditClick (index) {
        const { _id, type } = $scope.contentManagement.articleModuleArray[index];
        localStorageService.set('module', JSON.stringify({ id: _id, type }));
        window.open('layout-module', '_blank');
      }

      function clear () {
        $scope.contentManagement.moduleType.select = undefined;
        $scope.contentManagement.customSearchText = '';
      }

      function onCopyIdClick (id) {
        ywUtil.cloneText(id);
        $scope.success('已複製');
      }

      function customCancel () {
        if ($scope.currentPageState === 'edit') {
          $scope.pageStateHistory.push('list');
        } else if ($scope.currentPageState === 'add') {
          $scope.pageStateHistory.pop();
        }
      }

      $scope.inputItems = [
        {
          title: '基本設定',
          disable: false,
          inputs: [
            { label: '公司名稱*', type: 'text', index: 'name' },
            { label: '公司簡稱*', type: 'text', index: 'shortname' },
            { label: '統編*', type: 'text', index: 'vatNo' },
            { label: '合約編號', type: 'text', index: '_contractNo' },
            { label: '合約到期日', type: 'date-picker', index: '_contractTo', setToEnd: true },
            { label: '[公司地址]', type: 'empty' },
            {
              label: '縣市',
              type: 'dropdown',
              selected: _.get($scope, 'currentActionItem.address.county'),
              options: Object.keys(address),
              changed: (value) => {
                $scope.syncDistrictOptions(value);
                _.set($scope.currentActionItem, 'address.county', value || '');
              }
            },
            $scope.districtInput,
            {
              label: '地址',
              type: 'text',
              class: 'long',
              index: '_addressDetails',
              placeholder: '詳細地址',
              describeClass: 'color-red'
            },
            { label: '', type: 'empty' },
            { label: '負責人姓名', type: 'text', index: '_ownerName' },
            { label: '負責人身分證字號', type: 'text', index: '_ownerTwId' },
            { label: '負責人手機', type: 'text', index: '_ownerPhone' },
            { label: '負責人email*', type: 'text', index: '_ownerEmail', class: 'long' },
            {
              label: '帳務接收email',
              type: 'mails',
              index: 'accountReceiptMails',
              addOne: () => {
                if (!_.isArray($scope.currentActionItem.accountReceiptMails)) $scope.currentActionItem.accountReceiptMails = [];
                $scope.currentActionItem.accountReceiptMails.push('');
              },
              removeOne: (index) => {
                $scope.currentActionItem.accountReceiptMails.splice(index, 1);
              }
            },
            {
              label: '公司電話',
              type: 'local-phone',
              index: 'phone',
              extensionIndex: 'phoneExtension'
            },
            { label: '公司傳真', type: 'text', index: 'fax' },
            { label: '訂單聯繫電話', type: 'order-contacts', index: 'orderContacts' },
            { label: '公司網址', type: 'text', index: 'url' },
            { label: '員工人數', type: 'number', index: 'employeeCount' },
            { label: '資本額(萬)', type: 'number', index: 'capital' },
            { label: '保證金', type: 'number', index: 'securityDeposit' },
            { label: ' ', type: 'checkbox', index: '_isActiveSmsSending', checkboxes: [$scope.isActiveSmsSendingCheckbox] },
            {
              label: '網址辨識名稱',
              type: 'text',
              class: 'long',
              index: 'urlId',
              info: '由此自定網址名稱，使用全英文小寫或數字命名，可使用"-"及"_"分隔，例 interior-select，不設定則系統預設使用id'
            },
            { label: '排序', type: 'number', index: 'sortOrder', info: '數值越大越前面' },
            { label: '內部備註', type: 'textarea', index: 'note' },
            { type: 'action', customCancel }
          ],
          index: 0
        },
        {
          title: '服務方案與派案',
          disable: !hasCreatedVendor,
          inputs: [
            {
              type: 'service-capacity',
              index: '_serviceCapacities',
              contactsIndex: '_venderContactsList'
            },
            {
              label: '特殊休息日設定',
              type: 'not-service-in',
              index: 'notServiceIn',
              openNotServiceInDialog: $async(async (dateList) => {
                const dialogData = {
                  title: '設定特殊休息日',
                  templateUrl: '/view/dialog/date-picker.html',
                  confirmValue: _.clone(dateList),
                  datePickerOptions: {
                    customClass: function (data) {
                      if (dialogData.confirmValue.indexOf(data.date.setHours(0, 0, 0, 0)) > -1) {
                        return 'multiple selected';
                      }
                      return 'multiple';
                    }
                  }
                };
                item.notServiceIn = await confirmDialog.openConfirm(dialogData);
                item._notServiceInModified = true;
              })
            },
            { type: 'action', customCancel }
          ]
        },
        {
          title: '刷卡金流設定',
          disable: !hasCreatedVendor,
          inputs: [
            { label: '[藍新商店設定]', type: 'empty' },
            { label: '一般商店merchantId', type: 'text', index: '_spgatewayMerchantId' },
            { label: '一般商店hashKey', type: 'text', index: '_spgatewayHashKey' },
            { label: '一般商店hashIV', type: 'text', index: '_spgatewayHashIV' },
            { label: '3D驗證merchantId', type: 'text', index: '_spgatewayFor3DVerificationMerchantId' },
            { label: '3D驗證hashKey', type: 'text', index: '_spgatewayFor3DVerificationHashKey' },
            { label: '3D驗證hashIV', type: 'text', index: '_spgatewayFor3DVerificationHashIV' },
            { type: 'empty', info: '欄位選填，用戶端僅會顯示有輸入內容的欄位' },
            { label: '[匯款帳戶設定]', type: 'empty', labelInfo: '選填；若有任一欄位有填入內容，則銀行代碼及銀行帳號為必填。用戶端僅會顯示有輸入內容的欄位。' },
            { label: '銀行名稱', type: 'text', index: '_bankAccountBankName' },
            { label: '銀行代碼', type: 'text', index: '_bankAccountCode' },
            { label: '銀行分行名稱', type: 'text', index: '_bankAccountBranchName' },
            { label: '銀行戶名', type: 'text', index: '_bankAccountAccountName' },
            { label: '銀行帳號', type: 'text', index: '_bankAccountNo' },
            { type: 'action', customCancel }
          ]
        },
        {
          title: '介紹資訊',
          disable: !hasCreatedVendor,
          inputs: [
            {
              label: '資訊顯示設定',
              type: 'radio',
              index: '_introductionInfoIsVisible',
              radios: [{ value: true, label: '顯示' }, { value: false, label: '隱藏' }],
              info: '用於控制該廠商資訊內頁是否可被查看，隱藏時，進入該廠商介紹網址則轉導至廠商列表頁'
            },
            {
              label: '列表顯示設定',
              type: 'radio',
              index: '_introductionInfoIsFilterListVisible',
              radios: [{ value: true, label: '顯示' }, { value: false, label: '隱藏' }],
              info: '用於控制該廠商資料是否可顯示於廠商列表中，隱藏時，該廠商不可被搜尋且不出現於廠商預設列表中'
            },
            {
              label: '居家推薦標章顯示設定',
              type: 'radio',
              index: '_introductionInfoIsRecommendedMarkVisible',
              radios: [{ value: true, label: '顯示' }, { value: false, label: '隱藏' }],
              info: '設定居家推薦標章顯示或隱藏'
            },
            { label: '廠商公告', type: 'textarea', class: 'long', index: '_introductionInfoAnnouncement' },
            { label: '特色優惠', type: 'textarea', class: 'long', index: '_introductionInfoFavorableDiscount' },
            {
              label: '廠商Logo',
              subLabel: '建議寬300 X 高300',
              nestedLabel: '限制0.5MB',
              type: 'single-file',
              index: '_introductionInfoLogoUrl',
              originIndex: '_introductionInfoLogo',
              class: 'images-wrapper',
              files: $scope.introLogoFile,
              success: $scope.singleFileUploaded,
              error: $scope.alert,
              validate: { limitSize: 500000 }
            },
            {
              label: '廠商代表圖-桌機版',
              subLabel: '建議寬2400 X 高600',
              nestedLabel: '限制1MB',
              type: 'single-file',
              index: '_introductionInfoTypicalImageUrl',
              originIndex: '_introductionInfoTypicalImage',
              class: 'images-wrapper',
              files: $scope.introTypicalImageFile,
              success: $scope.singleFileUploaded,
              error: $scope.alert,
              validate: { limitSize: 1000000 }
            },
            {
              label: '廠商代表圖-手機版',
              subLabel: '建議寬1200 X 高400',
              nestedLabel: '限制1MB',
              type: 'single-file',
              index: '_introductionInfoTypicalMobileImageUrl',
              originIndex: '_introductionInfoTypicalMobileImage',
              class: 'images-wrapper',
              files: $scope.introTypicalMobileImageFile,
              success: $scope.singleFileUploaded,
              error: $scope.alert,
              validate: { limitSize: 1000000 }
            },
            {
              label: '廠商特色標籤',
              type: 'multi-selection-dropdown',
              index: '_qualityTags',
              selectedList: (item._qualityTags || []).map(tag => tag.name),
              selectedLimit: 30,
              options: (item._qualityTagsList || []).map(tag => tag.name),
              searchable: true,
              blankItem: '請選擇',
              changed: (qualityTags) => {
                item._qualityTags = item._qualityTagsList
                  .filter(tag => qualityTags.includes(tag.name));
              }
            },
            {
              label: '縣市',
              type: 'multi-selection-dropdown',
              index: '_introductionInfoServiceCounties',
              selectedList: item._introductionInfoServiceCounties,
              options: address.getSortedCounties,
              blankItem: '請選擇',
              changed: (counties) => {
                item._introductionInfoServiceCounties = counties;
              }
            },
            {
              label: '接案預算門檻*',
              type: 'number',
              index: 'customerBudget',
              describe: '萬元',
              info: '必填，單位為"萬"元，支援小數點，輸入0則為"不拘"，用戶下單有預先選擇的廠商時，如果用戶填入預算低於該值，下單前會進行提醒，下單後不會預先進行派單(系統會於後台訂單明細備註區發送此狀況提醒通知)'
            },
            {
              label: '服務項目',
              type: 'multi-selection-dropdown',
              index: '_serviceItems',
              selectedList: (item._serviceItems || []).map(service => service.name),
              options: (item._serviceItemsList || []).map(service => service.name),
              searchable: true,
              blankItem: '請選擇',
              changed: (serviceItems) => {
                item._serviceItems = item._serviceItemsList
                  .filter(service => serviceItems.includes(service.name));
              }
            },
            {
              label: '服務介紹',
              type: 'vender-service-intro',
              index: '_introductionInfoServiceIntroduction',
              add: () => item._introductionInfoServiceIntroduction.push({ file: {} }),
              remove: (index) => item._introductionInfoServiceIntroduction.splice(index, 1),
              moved: (index) => item._introductionInfoServiceIntroduction.splice(index, 1),
              logoUploadSuccess: (result, index) => {
                item._introductionInfoServiceIntroduction[index].image = result;
              },
              error: $scope.alert,
              removeLogo: index => {
                item._introductionInfoServiceIntroduction[index].image = null;
              }
            },
            {
              label: '網址',
              type: 'vender-url',
              index: '_introductionInfoUrls',
              add: () => item._introductionInfoUrls.push({ file: {} }),
              remove: (index) => item._introductionInfoUrls.splice(index, 1),
              moved: (index) => item._introductionInfoUrls.splice(index, 1),
              error: $scope.alert
            },
            {
              label: '電話',
              type: 'local-phone',
              index: '_introductionInfoVoIP',
              maxlength: 12,
              extensionIndex: '_introductionInfoExtension',
              extensionMaxlength: 20,
              placeholder: '填寫如02-1234-1234'
            },
            // {
            //   label: ' ',
            //   type: 'checkbox',
            //   index: 'isShowRecordHint',
            //   checkboxes: [$scope.isShowHintCheckbox]
            // },
            {
              label: '榮譽勳章',
              type: 'medal-of-honor',
              index: '_introductionInfoMedalOfHonor',
              add: () => item._introductionInfoMedalOfHonor.push({ file: {} }),
              remove: (index) => item._introductionInfoMedalOfHonor.splice(index, 1),
              moved: (index) => item._introductionInfoMedalOfHonor.splice(index, 1),
              imageUploadSuccess: (result, index) => {
                item._introductionInfoMedalOfHonor[index].image = result;
              },
              error: $scope.alert,
              removeImage: index => {
                item._introductionInfoMedalOfHonor[index].image = null;
              }
            },
            {
              label: '廠商簡述',
              type: 'textarea',
              index: '_introductionInfoBrieflyDescribe'
            },
            $scope.contentManagement,
            { label: '設計師介紹', type: 'rich-editor', index: '_introductionInfoDesignerIntroduction' },
            {
              label: '顯示預約服務按鈕',
              type: 'radio',
              index: '_isDisplayGotoReservation',
              radios: [{ value: true, label: '顯示' }, { value: false, label: '隱藏' }],
              info: '按鍵『顯示』代表為有提供居家訂單服務廠商(轉介服務廠商)，『隱藏』代表純廣告廠商。同時控制廠商顯示AD標章圖示、廠商代表圖右上角AD文案 。'
            },
            { label: '預約服務按鈕顯示文字', type: 'text', index: '_gotoReservationDefaultName', info: '最多輸入8字，沒有填寫則顯示"立即預約服務"', placeholder: '立即預約服務', maxlength: 8 },
            {
              label: '立即預約服務按鈕跳轉',
              type: 'navigation-setting',
              index: 'navData',
              buttonLabel: '設定'
            },
            { type: 'action', customCancel }
          ]
        },
        {
          title: '其他設定',
          disable: !hasCreatedVendor,
          inputs: [
            { label: '[列印驗收單設定]', type: 'empty' },
            { label: '不顯示訂金金額', type: 'switch', index: '_acceptanceSettingIsHiddenTotal' },
            { label: '不顯示客戶電話', type: 'switch', index: '_acceptanceSettingIsHiddenPhone' },
            { label: ' ', type: 'empty' },
            {
              label: '[廠商標籤]',
              type: 'multi-searchable-obj-picker',
              index: 'labels',
              title: '廠商標籤',
              resource: Restangular.service('labels'),
              titleField: 'name',
              filterField: 'name',
              queryParams: { target: 'vender', limit: 500 },
              closeCityFilter: true,
              showSelectedItemsCount: true
            },
            { type: 'action', customCancel }
          ]
        }
      ];

      $scope.status.isLoading = false;
    }
  }];
