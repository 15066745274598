import { inputLimit } from '../../../share/input-limit.js';

export default ['$scope', 'confirmDialog', 'LayoutModule', 'layoutModuleUtils',
  function controller ($scope, confirmDialog, LayoutModule, layoutModuleUtils) {
    const self = this;
    self.backGroundImageFile = {};
    self.imageFiles = {};
    $scope.singleFileUploaded = $scope.singleFileUploaded; // eslint-disable-line
    $scope.inputItems = [];
    $scope.dragContents = [];
    $scope.transform = transform;
    $scope.mainAction._validator = validator;

    function validator () {
      const item = $scope.currentActionItem;
      console.log('validator:', item);
      if (!item.name) {
        return $scope.alert('模組名稱為必填');
      }
      if (item.graphicMoreModule?.backgroundImage?.target === 'image' && !item.graphicMoreModule?.backgroundImage?.image) {
        return $scope.alert('需上傳背景圖片');
      }
      if (_.isEmpty(item.graphicMoreModule?.contents)) {
        return $scope.alert('請至少建立一組模組內容');
      }
      if (!_.isEmpty(item.graphicMoreModule?.contents)) {
        if (item.graphicMoreModule?.contents.some(content => !content.image)) {
          return $scope.alert('需上傳模組內容所有圖片');
        }
        if (item.graphicMoreModule?.contents.some(content => !content.titleAndAlt)) {
          return $scope.alert('需填寫模組內容所有圖片title&alt');
        }
      }
      return true;
    }

    function transform () {
      const item = $scope.currentActionItem;
      const contentsData = [];

      item.contents.forEach((content) => {
        const transformContent = {
          image: content[0].image,
          titleAndAlt: content[1].titleAndAlt,
          title: content[2].title,
          paragraph: content[3].paragraph,
          link: content[4].link
        };
        contentsData.push(transformContent);
      });

      item.graphicMoreModule = {
        paragraphTitle: item.paragraphTitle,
        font: item.font,
        backgroundImage: {
          target: item.target,
          image: item.target === 'image' ? item.backgroundImage : null,
          color: item.target === 'color' ? item.color : ''
        },
        contents: contentsData,
        randomArrangement: item.randomArrangement
      };

      console.log('after transform: ', item);
    }

    async function syncCurrentActionItem () {
      const item = $scope.currentActionItem;
      const isEdit = $scope.currentPageState === 'edit';
      if (isEdit) {
        const layoutModule = await LayoutModule.one(item._id).customGET();

        item.style = layoutModule?.style;
        item.isVisible = layoutModule?.isVisible;
        item.label = layoutModuleUtils.getLayoutModuleTypeName(layoutModule.type);
        item.randomArrangement = layoutModule?.graphicMoreModule?.randomArrangement;
        item.font = layoutModule.graphicMoreModule?.font;
        item.paragraphTitle = layoutModule.graphicMoreModule?.paragraphTitle || '';
        item.target = layoutModule.graphicMoreModule?.backgroundImage?.target;

        if (item.target !== 'none') {
          if (item.target === 'image') {
            item._backgroundImageUrl = layoutModule.graphicMoreModule?.backgroundImage?.image;
            item.backgroundImage = layoutModule.graphicMoreModule?.backgroundImage?.image?._id;
          } else {
            item.color = layoutModule.graphicMoreModule?.backgroundImage?.color;
          }
        }
        item.contents = layoutModule[`${item.type}Module`]?.contents;
      }
      $scope.inputItems = getModuleAction(item, $scope);
    }

    const getModuleAction = (currentActionItem, $scope) => {
      currentActionItem.visibleItemIndex = 0;
      currentActionItem.logListEvent = (index) => {
        currentActionItem.newIndex = index;
      };

      const openColorPickerModal = async () => {
        const color = await confirmDialog.openConfirm({
          title: '',
          templateUrl: '/view/dialog/color-picker-dialog.html',
          confirmValue: currentActionItem.color
        });
        currentActionItem.color = color;
      };

      $scope.dragContents = [
        {
          label: '*圖片：',
          remark: '圖片比例：1:1、建議尺寸：寬600 x 高600，可上傳 PNG 圖片，限制 1 MB',
          type: 'module-file',
          index: '_imageUrl',
          originIndex: 'image',
          class: 'images-wrapper',
          files: $scope.imageFiles,
          success: (result, item) => {
            console.log('moderator imageUploaded: ', item);
            item._imageUrl = result;
            item.image = result._id;
          },
          error: $scope.alert,
          validate: { limitSize: 1000000 }
        },
        {
          label: '*圖片title & alt：',
          type: 'module-text',
          index: 'titleAndAlt'
        },
        {
          label: '標題：',
          type: 'module-text',
          index: 'title',
          remark: '字數限制：256字 (未填不顯示，建議總字數不要相差超過兩行)',
          maxlength: inputLimit.default
        },
        {
          label: '段落：',
          type: 'module-textarea',
          index: 'paragraph',
          remark: '字數限制：256字(未填則不顯示，建議總字數不要相差超過兩行)',
          maxlength: inputLimit.default
        },
        {
          label: '連結：',
          type: 'module-link',
          index: 'link'
        }
      ];

      if (currentActionItem.contents) {
        for (let i = 0; i < currentActionItem.contents?.length; i++) {
          const cloneGraphicMoreContents = _.cloneDeep($scope.dragContents);
          const moduleContent = currentActionItem.contents[i];
          _.forIn(moduleContent, (value, key) => {
            if (value) {
              if (key === 'image') {
                const result = _.find(cloneGraphicMoreContents, function (item) {
                  if (item.originIndex === key) {
                    return true;
                  }
                });
                if (result) {
                  result._imageUrl = value;
                  result.image = value._id;
                }
              } else {
                const result = _.find(cloneGraphicMoreContents, function (item) {
                  if (item.index === key) {
                    return true;
                  }
                });
                if (result) result[key] = value;
              }
            }
          });
          currentActionItem.contents.splice(i, 1, _.cloneDeep(cloneGraphicMoreContents));
        }
      } else {
        currentActionItem.color = '#ffffff';
        currentActionItem.target = 'image';
        currentActionItem.randomArrangement = false;
        currentActionItem.style = 'moreUsed';
        currentActionItem.font = 'white';
        currentActionItem.contents = [_.cloneDeep($scope.dragContents)];
      }

      $scope.mainImageUpload = {
        label: ' ',
        type: 'single-file',
        index: '_backgroundImageUrl',
        originIndex: 'backgroundImage',
        class: 'images-wrapper',
        files: $scope.backGroundImageFile,
        success: $scope.singleFileUploaded,
        error: $scope.alert,
        showIndex: currentActionItem.target === 'image'
      };

      $scope.mainImageColor = {
        label: ' ',
        index: 'color',
        type: 'color-picker-dialog',
        color: currentActionItem.color,
        onEditColor: openColorPickerModal,
        showIndex: currentActionItem.target === 'color'
      };

      const dragModules = layoutModuleUtils.getDragModules($scope, 'contents');

      return (
        [
          { label: '模組類型：', type: 'description', description: currentActionItem.label },
          { label: '*模組名稱：', type: 'text', index: 'name', remark: '不會顯示於前台' },
          {
            label: '顯示設定',
            type: 'radio',
            index: 'isVisible',
            radios: [
              { value: true, label: '顯示' },
              { value: false, label: '隱藏' }
            ]
          },
          {
            label: '每週隨機排序內容',
            type: 'radio',
            index: 'randomArrangement',
            radios: [
              { value: true, label: '開啟' },
              { value: false, label: '關閉' }
            ],
            info: '開啟設定時，內容順序將在每週一進行隨機排序'
          },
          {
            label: '樣式選擇',
            type: 'radio-with-image',
            index: 'style',
            radios: [
              { value: 'moreUsed', label: '圖小-顯示較多則數 (建議5則以上)', image: '/img/graphicMore_moreUsed.png' },
              { value: 'fewerUsed', label: '圖大-顯示較少則數 (建議3則以上)', image: '/img/graphicMore_fewerUsed.png' }
            ]
          },
          { type: 'underline' },
          { label: '段落標題：', type: 'text', index: 'paragraphTitle', remark: 'H1、字數限制：256字(未填則不顯示，建議8字內)', maxlength: inputLimit.default },
          {
            label: '*背景圖片：',
            type: 'radio',
            index: 'target',
            radios: [
              { value: 'image', label: '上傳圖檔', remark: '圖片建議尺寸寬1200 X 高600，可上傳 PNG 圖片，限制1MB' },
              { value: 'color', label: '單色', remark: '點選色塊可換色' },
              { value: 'none', label: '無' }
            ],
            validate: { limitSize: 1000000 },
            changed: (value) => {
              $scope.mainImageColor.showIndex = value === 'color';
              $scope.mainImageUpload.showIndex = value === 'image';
            }
          },
          $scope.mainImageUpload,
          $scope.mainImageColor,
          {
            label: '*文字樣式：',
            type: 'radio',
            index: 'font',
            radios: [
              { value: 'white', label: '白字(深色背景用)' },
              { value: 'black', label: '黑字(淺色背景用)' }
            ]
          },
          {
            type: 'titleLabel',
            titleLabel: '模組內容'
          },
          {
            type: 'drag-module',
            module: 'graphicMore',
            subType: 'graphicDragModule-wrapper',
            index: 'contents',
            itemDropped: dragModules.itemDropped,
            itemRemoved: dragModules.itemRemoved,
            itemAdded: dragModules.itemAdded,
            itemVisible: dragModules.itemVisible,
            itemDragend: dragModules.itemDragend
          },
          { type: 'action' }
        ]
      );
    };

    $scope.$watch('currentActionItem', syncCurrentActionItem);
  }];
