import moment from 'moment';

export default ['$scope', '$async', 'Upload', 'API_URL', 'currentUser', 'popupSelectionDialog', 'Restangular', 'ArticleClassification', 'address',
  function controller ($scope, $async, Upload, API_URL, currentUser, popupSelectionDialog, Restangular, ArticleClassification, address) {
    const self = this;

    self.imageFiles = {};
    self.imageDoubleFiles = {};
    self.uploaded = $scope.imageUploaded;
    self.singleFileUploaded = $scope.singleFileUploaded;
    $scope.inputItems = [];
    $scope.transform = transform;
    $scope.mainAction._validator = validator;

    $scope.$watch('currentActionItem', syncCurrentActionItem);

    function validator () {
      console.log('validator:', $scope.currentActionItem);
      const item = $scope.currentActionItem;

      if (!item.name) {
        return $scope.alert('活動名稱為必填');
      }

      if (item.name && item.name.length > 8) {
        return $scope.alert('活動名稱最多輸入8字');
      }

      if (_.isEmpty(item.visibleSources)) {
        return $scope.alert('請至少選擇一項顯示通路');
      }

      const articlepageChecked = $scope.displayAtCheckboxes
        .find(checkbox => checkbox.index === 'articlepage' && checkbox.checked);
      if (articlepageChecked && _.isEmpty(item._articlepageArticleClassification)) {
        return $scope.alert('請至少選擇一項文章頁面分類');
      }
      const articleIdleReminderChecked = $scope.displayAtCheckboxes
        .find(checkbox => checkbox.index === 'articleIdleReminder' && checkbox.checked);
      if (articleIdleReminderChecked && _.isEmpty(item._articleIdleReminderArticleClassification)) {
        return $scope.alert('請至少選擇一項文章閒置提醒分類');
      }

      if (!item._visibilityDateStart || !item._visibilityDateEnd) {
        return $scope.alert('活動時間起迄為必填');
      }

      if (!item.image || !item.imageDouble) {
        return $scope.alert('需上傳Banner桌機版、手機版圖片');
      }

      console.log('currentActionItem: ', item);
      return true;
    }

    function transform () {
      const item = $scope.currentActionItem;

      item.visibility = {
        period: {
          from: item._visibilityDateStart,
          to: item._visibilityDateEnd
        },
        location: {
          type: item._visibilityLocationType
        }
      };
      if (item._visibilityLocationType === 'regions') {
        item.visibility.location.regions = item._counties.map(county => ({
          county,
          districts: item._districts
            .filter(district => district.startsWith(county))
            .map(district => district.split('-')[1])
        }));
      } else if (item._visibilityLocationType === 'range') {
        item.visibility.location.range = {
          radiusM: item._rangeRadiusKM * 1000,
          lng: item._rangeLoc.longitude,
          lat: item._rangeLoc.latitude
        };
      }

      item.visibleSources = _.clone(item._visibleSources.webviews);
      if (item._visibleSources.unlimited) {
        item.visibleSources = ['unlimited'];
      } else {
        if (item._visibleSources.lsApp) { item.visibleSources.push('lsApp'); }
        if (item._visibleSources.lsWeb) { item.visibleSources.push('lsWeb'); }
      }

      item.displayInfo = $scope.displayAtCheckboxes
        .filter(checkbox => checkbox.checked)
        .map(checkbox => {
          const result = { at: checkbox.index };
          if (checkbox.index === 'articlepage') {
            result.articleClassifications = item._articlepageArticleClassification;
          } else if (checkbox.index === 'articleIdleReminder') {
            result.articleClassifications = item._articleIdleReminderArticleClassification;
          }
          return result;
        });

      if (!_.isNumber(item.order)) {
        item.order = 0;
      }

      console.log('transform finished: ', item);
    }

    function syncCurrentActionItem () {
      const item = $scope.currentActionItem;

      if ($scope.currentPageState === 'add') {
        item._visibilityDateStart = moment().startOf('day');
      } else if ($scope.currentPageState === 'edit') {
        item._visibilityDateStart = _.get(item, 'visibility.period.from');
        item._visibilityDateEnd = _.get(item, 'visibility.period.to');

        item._visibilityLocationType = _.get(item, 'visibility.location.type');
        if (item._visibilityLocationType === 'regions') {
          item._counties = _.get(item, 'visibility.location.regions', [])
            .map(region => region.county);
          item._districts = _.chain(_.get(item, 'visibility.location.regions', []))
            .map(region => region.districts.map(district => `${region.county}-${district}`))
            .flatMap()
            .value();
        } else if (item._visibilityLocationType === 'range') {
          item._rangeRadiusKM = _.get(item, 'visibility.location.range.radiusM', 0) / 1000;
          item._rangeLoc = {
            longitude: _.get(item, 'visibility.location.range.lng'),
            latitude: _.get(item, 'visibility.location.range.lat')
          };
        }

        item._imageUrl = item.image;
        item.image = _.get(item, '_imageUrl._id');
        item._imageDoubleUrl = item.imageDouble;
        item.imageDouble = _.get(item, '_imageDoubleUrl._id');

        const webviews = _.filter(item.visibleSources, source => !['lsWeb', 'lsApp', 'unlimited'].includes(source));
        item._visibleSources = {
          lsWeb: !!_.find(item.visibleSources, source => source === 'lsWeb'),
          lsApp: !!_.find(item.visibleSources, source => source === 'lsApp'),
          unlimited: !!_.find(item.visibleSources, source => source === 'unlimited'),
          others: !!webviews.length,
          webviews
        };
      }

      /* address settings */
      $scope.countyInput = {
        label: '縣市',
        type: 'multi-selection-dropdown',
        index: '_counties',
        selectedList: item._counties,
        options: address.getSortedCounties,
        blankItem: '縣市',
        changed: (counties) => {
          item._counties = counties;
          $scope.districtInput.options = address.syncDistrictOptions(counties);
          item._districts =
            (item._districts || []).filter(
              district => counties.find(county => district.startsWith(county))
            );
        },
        showIndex: _.get(item, 'visibility.location.type') === 'regions'
      };
      $scope.districtInput = {
        label: '地區',
        type: 'multi-selection-dropdown',
        index: '_districts',
        selectedList: item._districts,
        options: address.syncDistrictOptions($scope.countyInput.selectedList),
        blankItem: '地區',
        byCategory: true,
        changed: (value) => {
          item._districts = value;
        },
        showIndex: _.get(item, 'visibility.location.type') === 'regions'
      };
      $scope.syncDistrictOptions = function (county) {
        $scope.districtInput.options = address[county];
      };
      if (_.hasIn($scope, 'currentActionItem.address.county')) { $scope.syncDistrictOptions($scope.currentActionItem.address.county); }

      $scope.rangeRadiusKMInput = {
        label: '範圍(公里)',
        type: 'number',
        index: '_rangeRadiusKM',
        showIndex: _.get(item, 'visibility.location.type') === 'range'
      };
      $scope.rangeLocInput = {
        label: '範圍中心點',
        type: 'single-coordinate-input',
        index: '_rangeLoc',
        alertCallback: $scope.alert,
        showIndex: _.get(item, 'visibility.location.type') === 'range'
      };

      $scope.displayAtCheckboxes = [
        {
          value: false,
          label: '首頁',
          index: 'homepage',
          checked: !!(item.displayInfo || []).find(info => info.at === 'homepage')
        },
        {
          value: false,
          label: '文章頁面',
          index: 'articlepage',
          checked: !!(item.displayInfo || []).find(info => info.at === 'articlepage')
        },
        {
          value: false,
          label: '文章閒置提醒',
          index: 'articleIdleReminder',
          checked: !!(item.displayInfo || []).find(info => info.at === 'articleIdleReminder')
        },
        {
          value: false,
          label: '文章列表廣告',
          index: 'articleListAdvertisement',
          checked: !!(item.displayInfo || []).find(info => info.at === 'articleListAdvertisement')
        }
      ];

      item._articlepageArticleClassification = _.chain(item.displayInfo || [])
        .find(info => info.at === 'articlepage')
        .get('articleClassifications')
        .value() || [];
      $scope.displayAtArticleInput = {
        label: '文章頁面分類',
        type: 'multi-searchable-obj-picker',
        index: '_articlepageArticleClassification',
        title: '文章頁面分類',
        resource: ArticleClassification,
        filterField: 'name',
        titleField: 'name',
        closeCityFilter: true,
        showSelectedItemsCount: true,
        showIndex: !!(item.displayInfo || []).find(info => info.at === 'articlepage')
      };

      item._articleIdleReminderArticleClassification = _.chain(item.displayInfo || [])
        .find(info => info.at === 'articleIdleReminder')
        .get('articleClassifications')
        .value() || [];
      $scope.displayAtArticleClassificationInput = {
        label: '文章閒置提醒分類',
        type: 'multi-searchable-obj-picker',
        index: '_articleIdleReminderArticleClassification',
        title: '文章閒置提醒分類',
        resource: ArticleClassification,
        filterField: 'name',
        titleField: 'name',
        closeCityFilter: true,
        showSelectedItemsCount: true,
        showIndex: !!(item.displayInfo || []).find(info => info.at === 'articleIdleReminder')
      };

      $scope.inputItems = [
        { label: '活動名稱*', type: 'text', index: 'name', info: '最多輸入8字' },
        {
          label: '顯示通路*',
          type: 'banner-visible-sources',
          index: '_visibleSources',
          openSelector: $async(async () => {
            const dialogData = {
              title: '選擇特定來源',
              type: 'multiple',
              selectedList: item._visibleSources.webviews || [],
              showFooter: true,
              options: []
            };
            Restangular.service('systemSettings').getList({ key: 'sourceRestrictionSetting' })
              .then(res => {
                if (!res.error) {
                  dialogData.options = res[0].value.map(webview => ({
                    value: webview, label: webview
                  }));
                }
              });
            const result = await popupSelectionDialog.openConfirm(dialogData);
            item._visibleSources.webviews = result;
          }),
          othersClicked: (value) => {
            if (!value) {
              item._visibleSources.webviews = [];
            }
          }
        },
        {
          label: '版位*',
          type: 'checkbox',
          index: '_displayAt',
          checkboxes: $scope.displayAtCheckboxes,
          checked: (checked, option) => {
            if (option.index === 'articlepage') {
              $scope.displayAtArticleInput.showIndex = checked;
            } else if (option.index === 'articleIdleReminder') {
              $scope.displayAtArticleClassificationInput.showIndex = checked;
            }
          }
        },
        $scope.displayAtArticleInput,
        $scope.displayAtArticleClassificationInput,
        {
          label: '活動期間*',
          type: 'date-picker-duration',
          startIndex: '_visibilityDateStart',
          endIndex: '_visibilityDateEnd'
        },
        {
          label: '顯示區域限制',
          type: 'radio',
          index: '_visibilityLocationType',
          radios: [
            { value: 'noRestriction', label: '不限制' },
            { value: 'regions', label: '縣市行政區' },
            { value: 'range', label: '中心點範圍' }
          ],
          changed: (type) => {
            $scope.countyInput.showIndex = type === 'regions';
            $scope.districtInput.showIndex = type === 'regions';
            $scope.rangeRadiusKMInput.showIndex = type === 'range';
            $scope.rangeLocInput.showIndex = type === 'range';
          }
        },
        $scope.countyInput,
        $scope.districtInput,
        $scope.rangeRadiusKMInput,
        $scope.rangeLocInput,
        {
          label: 'Banner桌機版*:',
          subLabel: '寬2400 X 高600\n限制1MB',
          type: 'single-file',
          index: '_imageUrl',
          originIndex: 'image',
          class: 'images-wrapper',
          files: $scope.imageFiles,
          success: $scope.singleFileUploaded,
          error: $scope.alert,
          validate: { width: { limit: 2400 }, height: { limit: 600 }, limitSize: 1000000 }
        },
        {
          label: 'Banner手機版*:',
          subLabel: '寬1800 X 高1200\n限制1MB',
          type: 'single-file',
          index: '_imageDoubleUrl',
          originIndex: 'imageDouble',
          class: 'images-wrapper',
          files: $scope.imageDoubleFiles,
          success: $scope.singleFileUploaded,
          error: $scope.alert,
          validate: { width: { limit: 1800 }, height: { limit: 1200 }, limitSize: 1000000 }
        },
        { label: '排序', type: 'number', index: 'order', info: '數值越大越前面' },
        { label: '頁面跳轉', type: 'navigation-setting', index: 'navData', buttonLabel: '頁面跳轉' },
        {
          label: '顯示設定',
          type: 'radio',
          index: 'isVisible',
          radios: [{ value: true, label: '顯示' }, { value: false, label: '隱藏' }]
        },
        { type: 'action' }
      ];
    }
  }];
