// this directive can be migrate to yw-popup-selection... in the future!!

export default ['ywEvent', '$timeout', '$async', 'popupSelectionDialog', function directive (ywEvent, $timeout, $async, popupSelectionDialog) {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      options: '=ywOptions',
      label: '=ywLabel',
      changed: '&ywOnChange',
      fetchOptions: '&',
      fetchOptionsAfterClick: '=',
      selectedList: '=ywSelectedList',
      selectedLimit: '=ywSelectedLimit',
      disabled: '=ywDisabled',
      searchable: '=ywSearchable',
      byCategory: '=ywByCategory',
      hideCategory: '=ywHideCategory',
      imageUrl: '=ywImageUrl',
      activeStyle: '=',
      clicked: '&ywOnClick',
      blankItem: '=ywBlankItem'
    },
    template: `
      <div
        class="select-wrapper wrapper"
        ng-class="{disabled: disabled, 'active-control': (selected !== undefined || selectedList.length > 0) && activeStyle}"
        ng-click="disabled || onClick()"
      >
        <a class="select" ng-disabled="disabled">
          <span uib-tooltip="{{displayedLabel}}" tooltip-enable="selectedList.length" ng-bind="displayedLabel">
          </span>
          <span class="caret"></span>
        </a>
      </div>
    `,
    link: link
  };

  function link (scope, element, attr) {
    scope.onClick = $async(async function () {
      if (scope.clicked) {
        await scope.clicked();
      }
      scope.openPopupMenu();
    });

    scope.openPopupMenu = async () => {
      const options = scope.fetchOptionsAfterClick === true ? buildOptions(await scope.fetchOptions()) : buildOptions(_.get(scope, 'options', []));
      const clonedSelectedList = _.clone(_.get(scope, 'selectedList', []));

      popupSelectionDialog.openConfirm({
        title: _.get(scope, 'label', '請選擇'),
        type: 'multiple',
        options: options,
        selectedList: clonedSelectedList,
        byCategory: scope.byCategory,
        imageUrl: scope.imageUrl,
        hideCategory: scope.hideCategory,
        showFooter: true,
        searchable: scope.searchable
      }).then(selectedList => {
        if (scope.selectedLimit && selectedList.length > scope.selectedLimit) {
          ywEvent.emit('ALERT', '選擇數量不能超過 ' + scope.selectedLimit + ' 項');
        } else {
          scope.selectedList = selectedList;
        }
      });
    };

    // returned option format => { label: String, value: String, category: String(Optional) }
    function buildOptions (opts) {
      if (_.isArray(opts) && _.isEmpty(opts)) return [];
      if (_.isArray(opts) && _.isString(_.head(opts))) { return _.map(opts, opt => Object.assign({}, { label: opt, value: opt })); }
      return opts;
    }

    function setDisplayedText (options, selectedList) {
      if (_.isEmpty(selectedList)) {
        if (_.get(scope, 'blankItem')) {
          scope.displayedLabel = _.get(scope, 'blankItem', '請選擇');
        } else {
          scope.displayedLabel = _.get(scope, 'label', '請選擇');
        }
      } else {
        const selectedLabelList = _.map(selectedList, item => _.get(_.find(options, opt => _.eq(item, opt.value)), 'label'));
        scope.displayedLabel = _.join(selectedLabelList, ', ');
      }
    }

    setDisplayedText(buildOptions(_.get(scope, 'options', [])), _.get(scope, 'selectedList', []));

    scope.$watch('selectedList', (newValue, oldValue) => {
      if (_.isEqual(newValue, oldValue)) return;
      setDisplayedText(buildOptions(scope.options), scope.selectedList);

      $timeout(() => {
        scope.$apply();
        if (scope.changed) { scope.changed(); }
      });
    });

    scope.$watch('options', (newValue, oldValue) => {
      if (_.isEqual(newValue, oldValue)) return;
      setDisplayedText(buildOptions(scope.options), scope.selectedList);

      $timeout(() => {
        scope.$apply();
        if (scope.changed) { scope.changed(); }
      });
    });
  }
}];
