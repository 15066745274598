export default ['$scope', '$async', 'Upload', 'address', 'confirmDialog', 'ywUtil', 'User',
  function controller ($scope, $async, confirmDialog, ywUtil, User) {
    $scope.inputItems = [];
    $scope.transform = transform;
    $scope.mainAction._validator = validator;

    function validator () {
      const item = $scope.currentActionItem;
      // const isEdit = $scope.currentPageState === 'edit';
      console.log('validator:', item);
      if (!item.name) return $scope.alert('請輸入廠商特色標籤名稱');
      if (item.name.length > 20) {
        return $scope.alert('廠商特色標籤名稱最多輸入20字');
      }
      if (item.sort === undefined || item.sort < 0 || item.sort > 99999999) {
        return $scope.alert('排序數值應該在 0 到 99999999 之間');
      }
      return true;
    }

    function transform () {
      const item = $scope.currentActionItem;
      console.log('after transform: ', item);
    }

    function syncCurrentActionItem () {
      // const item = $scope.currentActionItem;

      $scope.inputItems = [
        {
          label: '標籤名稱*',
          type: 'text',
          index: 'name',
          maxlength: 20
        },
        {
          label: '排序',
          type: 'number',
          index: 'sort',
          info: '數值越大越前面',
          min: 0,
          max: 99999999
        },
        {
          label: '顯示設定',
          type: 'radio',
          index: 'isVisible',
          radios: [
            { value: true, label: '顯示' },
            { value: false, label: '隱藏' }
          ]
        },
        { type: 'action' }
      ];
    }

    $scope.$watch('currentActionItem', syncCurrentActionItem);
  }];
