export default ['$scope', '$async', '$state', 'ywUtil', 'QualityTag', 'API_URL', '$http', 'confirmDialog', 'currentUser',
  function controller ($scope, $async, $state, ywUtil, QualityTag, API_URL, $http, confirmDialog, currentUser) {
    $scope.filterItems = [];
    $scope.tableHead = [];
    $scope.initActionItem = initActionItem;

    const init = $async(async () => {
      // rendering options
      $scope.toolbar = ywUtil.getToolbarSettings();
      $scope.tableHead = ywUtil.getTableHeader();
      $scope.searchBar = ywUtil.getSearchbarSettings();
      ywUtil.initSearchBarOptions(
        $scope,
        [
          { btnName: '標籤名稱', placeholder: '請輸入廠商特色標籤名稱', params: 'name' }
        ]
      );

      $scope.filterItems = [
        {
          name: 'isVisible',
          label: '是否顯示',
          options: { true: '顯示', false: '隱藏' },
          changed: $scope.mainAction.setClearFilterTrue,
          type: 'noOwnAttrSelect'
        }
      ];

      $scope.queryPage = 'QualityTag'; // for GA

      $scope.mainAction.initResource(QualityTag);
    });

    init();

    $scope.delete = $async(async () => {
      const selectedItems = $scope.items.filter(item => item._selected);
      const selectedIds = selectedItems.map(item => item._id);
      if (!selectedItems.length) { return $scope.alert('請選擇要刪除的項目'); }

      await confirmDialog.openConfirm({ content: '確認刪除？', title: '刪除', isDangers: true });
      const res = await $http({
        url: `${API_URL}qualityTags/batchRemove`,
        method: 'DELETE',
        headers: {
          authorization: `Bearer ${currentUser.getToken()}`,
          'Content-Type': 'application/json'
        },
        data: { qualityTagIds: selectedIds }
      });
      if (res.error) {
        $scope.alert(res.error);
      } else if (res.data.result.isUsed) {
        const removedTagIds = res.data.result.removedTagIds;
        for (let i = $scope.items.length - 1; i > -1; i--) {
          if (removedTagIds.indexOf($scope.items[i]._id) > -1) $scope.items.splice(i, 1);
        }
        $scope.mainAction.pager.totalItems -= removedTagIds.length;
        $scope.alert('已排除廠商使用中的廠商特色標籤');
      } else {
        for (let i = $scope.items.length - 1; i > -1; i--) {
          if (selectedIds.indexOf($scope.items[i]._id) > -1) $scope.items.splice(i, 1);
        }
        $scope.mainAction.pager.totalItems -= selectedIds.length;
        $scope.success('刪除完成');
      }
    });

    function initActionItem () {
      $scope.currentActionItem = {
        isVisible: true,
        sort: 0
      };
    }
  }];
