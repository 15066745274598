export default ['$scope', '$state', '$filter', '$async', 'currentUser', 'ywEvent', 'APP_VERSION', 'serverVersion', 'ywDialog', 'User', 'Vender', 'Restangular', 'WebSocket', 'confirmDialog', 'ngDialog',
  function controller ($scope, $state, $filter, $async, currentUser, ywEvent, APP_VERSION, serverVersion, ywDialog, User, Vender, Restangular, WebSocket, confirmDialog, ngDialog) {
    $scope.appVersion = APP_VERSION;
    $scope.serverVersion = serverVersion.get();
    $scope.currentUser = currentUser;
    $scope.siteStuff.navItems = [];
    $scope.roleLabel = $filter('user-role')(_.get(currentUser.get(), 'roles'));

    const channelEnv = __NODE_ENV__ === 'dev' ? 'test' : __NODE_ENV__;
    const regChannels = _.cond([
      [role => role === 'serviceAdmin', () => [`${channelEnv}-serviceAdminChannel`]],
      [role => role === 'vender', () => [`venderChannel-${_.get(currentUser.get(), 'link.vender')}`]],
      [_.stubTrue, () => []]
    ])(currentUser.getRole());
    WebSocket.connect(regChannels);
    serverVersion.checkWebVersion();

    // server version control
    const serverVersionChanged = version => {
      console.log('ver: ', version);
      $scope.serverVersion = version;
    };
    const versionEventId = ywEvent.register('SERVER_VERSION_CHANGED', serverVersionChanged);
    $scope.$on('$destroy', () => {
      WebSocket.disconnect();
      ywEvent.unregister(versionEventId);
    });

    const handleNewWebVersion = data => {
      if (data.env !== __NODE_ENV__) return;
      if (data.version !== APP_VERSION) {
        console.log('Has new version!');
        ywEvent.emit('SYSTEM_ALERT', '系統版本已更新，請重新整理網頁(或按鍵盤F5)取得最新版本');
      }
    };

    const handleWebSocketNotification = data => {
      if (data.event === 'NEW_WEB_VERSION') handleNewWebVersion(data);
    };
    const handleWebSocketEvent = ywEvent.register('WEBSOCKET_NOTIFICATION', handleWebSocketNotification);
    $scope.$on('$destroy', () => ywEvent.unregister(handleWebSocketEvent));

    $scope.openNewInfoDialog = news => {
      confirmDialog.openConfirm({
        templateUrl: '/view/dialog/new-info-dialog.html',
        title: '',
        data: news,
        hideFooter: true,
        close: ngDialog.closeAll
      }, 'custom-width-600');
    };

    $scope.prehook = {
      confirmPassword: () => {
        if (!currentUser.getCommunity().isConfirmPwdInPayment) return true;
        return confirmDialog.openAlert({
          templateUrl: '/view/dialog/confirm-password.html',
          hideCancel: true
        }).then(pwd => {
          if (_.isEmpty(pwd)) return false;
          return User.varifyPassword(pwd);
        }).then(res => {
          if (res === false || res.error) {
            $state.go(currentUser.getInitialState());
            $scope.alert('密碼錯誤！', 'fixed');
            return false;
          }
          return true;
        });
      }
    };

    const getNavItems = $async(async function () {
      let navItems = [];
      switch (currentUser.getRole()) {
        case 'admin':
          navItems = [
            {
              label: '管理',
              url: 'admin.management',
              show: true,
              subItems: [
                { label: '居家帳號管理', url: 'admin.management/service-account', show: true }
              ]
            }
          ];
          break;
        case 'serviceAdmin':
          navItems = [
            {
              label: '來件管理',
              url: 'service-admin.order',
              show: currentUser.getPermission('console:tab:reservation'),
              subItems: [
                { label: '訂單列表', url: 'service-admin.order/reservation', show: true },
                { label: '工程類訂單列表', url: 'service-admin.order/unordered-reservation', show: true },
                { label: '會員管理', url: 'service-admin.order/customer', show: true },
                { label: '會員群組管理', url: 'service-admin.order/customer-group', show: true }
              ]
            },
            {
              label: '行銷管理',
              url: 'service-admin.marketing',
              show: currentUser.getPermission('console:tab:marketing'),
              subItems: [
                { label: '活動Banner管理', url: 'service-admin.marketing/banner', show: true },
                { label: 'TA3 Banner管理', url: 'service-admin.marketing/ta3-banner', show: true },
                { label: '優惠券活動管理', url: 'service-admin.marketing/coupon-event', show: true },
                { label: '優惠碼管理', url: 'service-admin.marketing/coupon', show: true },
                { label: '訊息與優惠管理', url: 'service-admin.marketing/notification', show: true }
              ]
            },
            {
              label: '服務管理',
              url: 'service-admin.service',
              show: currentUser.getPermission('console:tab:service'),
              subItems: [
                { label: '服務分類管理', url: 'service-admin.service/service-classification', show: true },
                { label: '服務方案列表', url: 'service-admin.service/service', show: true }
              ]
            },
            {
              label: '廠商帳號管理',
              url: 'service-admin.vender',
              show: currentUser.getPermission('console:tab:vender'),
              subItems: [
                { label: '廠商列表', url: 'service-admin.vender/vender', show: true },
                { label: '廠商特色標籤', url: 'service-admin.vender/quality-tag', show: true }
              ]
            },
            {
              label: '帳務管理',
              url: 'service-admin.bill',
              show: currentUser.getPermission('console:tab:accountManagement'),
              subItems: [
                { label: '帳務列表', url: 'service-admin.bill/bill', show: true },
                { label: '廠商繳款帳戶列表', url: 'service-admin.bill/account', show: true }
              ]
            },
            {
              label: '網站內容管理',
              url: 'service-admin.content',
              show: currentUser.getPermission('console:tab:websiteContent'),
              subItems: [
                { label: '自訂頁面管理', url: 'service-admin.content/layout', show: true },
                { label: '模組內容管理', url: 'service-admin.content/layout-module', show: true },
                { label: '文章管理', url: 'service-admin.content/article', show: true },
                { label: '文章分類', url: 'service-admin.content/article-classification', show: true },
                { label: '相簿管理', url: 'service-admin.content/album', show: true },
                { label: '關鍵字管理', url: 'service-admin.content/keyword', show: true },
                { label: '網站設定內容', url: 'service-admin.content/website-content', show: true },
                { label: '服務選單功能', url: 'service-admin.content/catalog', show: true }
              ]
            },
            {
              label: '其他設定',
              url: 'service-admin.others',
              show: currentUser.getPermission('console:tab:other'),
              subItems: [
                { label: '一般系統設定', url: 'service-admin.others/system-setting', show: true }
              ]
            }
          ];
          break;
        case 'vender':
          navItems = [
            { label: '訂單列表', url: 'vender.order/reservation', show: true },
            { label: '工程類訂單列表', url: 'vender.order/unordered-reservation', show: true },
            { label: '服務員管理', url: 'vender.vender-staff', show: true },
            { label: '訊息管理', url: 'vender.marketing/notification', show: true },
            { label: '帳務列表', url: 'vender.bill/bill', show: true },
            { label: '帳號資料維護', url: 'vender.account-setting', show: true },
            { label: '前往藍新金流服務平台', url: 'https://www.newebpay.com/main/login_center/single_login', show: true }
          ];
          break;
        default:
          console.log('default');
      }

      // assign navItems depends on current user srole
      $scope.siteStuff.navItems = navItems;

      // route to the first item if nothing selected(or routed)
      routeToFirstState();
    });

    getNavItems();

    function routeToFirstState () {
      const navItems = _.filter($scope.siteStuff.navItems, item => item.show);
      if (!navItems) { return false; }
      const isRootState = !_.isEmpty($state.current.name) && $state.current.name.indexOf('.') === -1;
      const hasMatchedState = _.some(navItems, item => {
        if (item.subItems) return _.some(item.subItems, subItem => $state.current.name === subItem.url);
        else return $state.current.name === item.url;
      });

      // 找出目前所在路徑
      $scope.currentNavItem = _.find(navItems, item => item.url === $state.current.name) || {};
      if (_.isEmpty($scope.currentNavItem)) {
        const subItems = [];
        _.forEach(navItems, item => {
          if (item.subItems) subItems.push(...item.subItems);
        });
        $scope.currentNavItem = _.find(subItems, item => item.url === $state.current.name);
      }
      if ($scope.currentNavItem && $scope.currentNavItem.preHook) $scope.currentNavItem.preHook();

      if (!isRootState || hasMatchedState) { return false; }

      if (!_.isEmpty(_.head(navItems).subItems)) {
        $state.go(_.head(_.head(navItems).subItems).url, _.head(_.head(navItems).subItems).data);
      } else {
        $state.go(_.head(navItems).url, _.head(navItems).data);
      }
    }

    $scope.navClicked = _.debounce(handleNavClick, 1000, {
      leading: true,
      trailing: false
    });

    async function handleNavClick ($event, url, data, gaAction, navItem) {
      if (gaAction) { ga('send', 'event', currentUser.getCommName(), gaAction, gaAction); }

      if (!_.isEmpty(navItem.subItems)) url = _.get(navItem, 'subItems.0.url');

      if (/^http/.test(url)) {
        window.open(url, '_blank');
      } else if ($event.metaKey || $event.ctrlKey) {
        // open in new tab
        window.open($state.href(url), '_blank');
      } else {
        const routeMethod = $state.is(url) ? $state.reload : $state.go;
        if (navItem.preHook) {
          const isValid = await navItem.preHook();
          if (isValid) routeMethod(url, data);
        } else {
          routeMethod(url, data);
        }
      }
    }
  }
];
