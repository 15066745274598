const permissionCheckResolver = (permission) =>
  [
    'currentUser',
    function (currentUser) {
      return currentUser.getPermission(permission)
        ? Promise.resolve()
        : Promise.reject(new Error('permission denied'));
    }
  ];

export default ['$stateProvider', '$urlRouterProvider', '$locationProvider',
  function ($stateProvider, $urlRouterProvider, $locationProvider) {
    // for any unmatched url
    $urlRouterProvider.otherwise('/');

    $stateProvider
    /**
         * admin pages
         */
      .state('admin', {
        url: '/admin',
        templateUrl: '/view/template/mainpage-wrapper.html',
        controller: 'adminController'
      })
      .state('admin.management/service-account', {
        url: '/service-account',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'serviceAccountController'
      })

    /**
         * service admin pages
         */
      .state('service-admin', {
        url: '/service-admin',
        templateUrl: '/view/template/mainpage-wrapper.html',
        controller: 'serviceAdminController'
      })
      .state('service-admin.vender/vender', {
        url: '/vender',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'saVenderController',
        resolve: { checkPermission: permissionCheckResolver('console:tab:vender') }
      })
      .state('service-admin.vender/quality-tag', {
        url: '/quality-tag',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'saVenderQualityTagController',
        resolve: { checkPermission: permissionCheckResolver('console:tab:vender') }
      })
      .state('service-admin.vender/advanced-filter-item', {
        url: '/vender/:venderId/advanced-filter-item',
        templateUrl: '/view/page/service-admin/vender-advanced-filter-item.html',
        controller: 'saVenderAdvancedFilterItemController',
        resolve: {
          currentVender: [
            '$stateParams',
            'Vender',
            function ($stateParams, Vender) {
              return Vender.one($stateParams.venderId).get();
            }
          ],
          checkPermission: permissionCheckResolver('console:tab:vender')
        }
      })
      .state('service-admin.vender/account', {
        url: '/vender/:venderId/account',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'saVenderAccountController',
        resolve: { checkPermission: permissionCheckResolver('console:tab:vender') }
      })
      .state('service-admin.vender/vender-staff', {
        url: '/vender/:venderId/vender-staff',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'venderVenderStaffController',
        resolve: { checkPermission: permissionCheckResolver('console:tab:vender') }
      })
      .state('service-admin.service/service-classification', {
        url: '/service-classification',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'saServiceClassificationController',
        resolve: { checkPermission: permissionCheckResolver('console:tab:service') }
      })
      .state('service-admin.service/service-classification/advanced-filter-item', {
        url: '/service-classification/:serviceClassificationId/advanced-filter-item',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'saAdvancedFilterItemController',
        resolve: {
          currentServiceClassification: [
            '$stateParams',
            'ServiceClassification',
            function ($stateParams, ServiceClassification) {
              return ServiceClassification.one($stateParams.serviceClassificationId).get();
            }
          ],
          checkPermission: permissionCheckResolver('console:tab:service')
        }
      })
      .state('service-admin.service/service', {
        url: '/service',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'saServiceController',
        resolve: { checkPermission: permissionCheckResolver('console:tab:service') }
      })
      .state('service-admin.bill/bill', {
        url: '/bill',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'saBillController',
        resolve: { checkPermission: permissionCheckResolver('console:tab:vender') }
      })
      .state('service-admin.bill/account', {
        url: '/account',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'saAccountController',
        resolve: { checkPermission: permissionCheckResolver('console:tab:vender') }
      })
      .state('service-admin.bill/account-transaction-detail', {
        url: '/account-transaction-detail?{vatNo}',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'saAccountTransactionDetailController',
        resolve: { checkPermission: permissionCheckResolver('console:tab:vender') }
      })
      .state('service-admin.order/reservation', {
        url: '/reservation',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'saReservationController',
        resolve: { checkPermission: permissionCheckResolver('console:tab:reservation') }
      })
      .state('service-admin.order/unordered-reservation', {
        url: '/unordered-reservation',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'saUnorderedReservationController',
        resolve: { checkPermission: permissionCheckResolver('console:tab:reservation') }
      })
      .state('service-admin.order/unordered-reservation-quotation', {
        url: '/unordered-reservation/{reservationId}/quotation/{quotationId}',
        templateUrl: '/view/page/service-admin/unordered-quotation.html',
        controller: 'saUnorderedReservationQuotationController',
        resolve: {
          checkPermission: permissionCheckResolver('console:tab:reservation')
        }
      })
      .state('service-admin.order/customer', {
        url: '/customer',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'saCustomerController',
        resolve: { checkPermission: permissionCheckResolver('console:tab:reservation') }
      })
      .state('service-admin.order/reservation-quotation', {
        url: '/reservation/{reservationId}/quotation',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'saReservationQuotationController',
        resolve: {
          currentReservation: [
            '$stateParams',
            'Reservation',
            function ($stateParams, Reservation) {
              return Reservation.one($stateParams.reservationId).get();
            }
          ],
          checkPermission: permissionCheckResolver('console:tab:reservation')
        }
      })
      .state('service-admin.order/customer-group', {
        url: '/customer-group',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'saCustomerGroupController',
        resolve: { checkPermission: permissionCheckResolver('console:tab:reservation') }
      })
      .state('service-admin.marketing/banner', {
        url: '/banner',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'saBannerController',
        resolve: { checkPermission: permissionCheckResolver('console:tab:marketing') }
      })
      .state('service-admin.marketing/ta3-banner', {
        url: '/ta3-banner',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'saTA3BannerController',
        resolve: { checkPermission: permissionCheckResolver('console:tab:marketing') }
      })
      .state('service-admin.marketing/coupon-event', {
        url: '/coupon-event',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'saCouponEventController',
        resolve: { checkPermission: permissionCheckResolver('console:tab:marketing') }
      })
      .state('service-admin.marketing/coupon', {
        url: '/coupon',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'saCouponController',
        resolve: { checkPermission: permissionCheckResolver('console:tab:marketing') }
      })
      .state('service-admin.marketing/notification', {
        url: '/notification',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'saNotificationController',
        resolve: { checkPermission: permissionCheckResolver('console:tab:marketing') }
      })
      .state('service-admin.others/system-setting', {
        url: '/system-setting',
        templateUrl: '/view/page/service-admin/system-setting.html',
        controller: 'saSystemSettingController',
        resolve: { checkPermission: permissionCheckResolver('console:tab:other') }
      })

      .state('service-admin.content/layout-module', {
        url: '/layout-module',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'saLayoutModuleController'
      })
      .state('service-admin.content/layout', {
        url: '/layout',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'saLayoutController',
        resolve: { checkPermission: permissionCheckResolver('console:tab:websiteContent') }
      })
      .state('service-admin.content/article', {
        url: '/article',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'saArticleController',
        resolve: { checkPermission: permissionCheckResolver('console:tab:websiteContent') }
      })
      .state('service-admin.content/article-classification', {
        url: '/article-classification',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'saArticleClassificationController',
        resolve: { checkPermission: permissionCheckResolver('console:tab:websiteContent') }
      })
      .state('service-admin.content/keyword', {
        url: '/keyword',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'saKeywordController',
        resolve: { checkPermission: permissionCheckResolver('console:tab:websiteContent') }
      })
      .state('service-admin.content/website-content', {
        url: '/website-content',
        component: 'websiteContentPage',
        resolve: { checkPermission: permissionCheckResolver('console:tab:websiteContent') }
      })
      .state('service-admin.content/catalog', {
        url: '/catalog',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'saCatalogController',
        resolve: { checkPermission: permissionCheckResolver('console:tab:websiteContent') }
      })
      .state('service-admin.content/album', {
        url: '/album',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'saAlbumController',
        resolve: { checkPermission: permissionCheckResolver('console:tab:websiteContent') }
      })
      .state('service-admin.content/photo-tag', {
        url: '/photo-classification/{photoClassificationId}/photo-tag',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'saPhotoTagController',
        resolve: {
          currentPhotoClassification: [
            '$stateParams',
            'Restangular',
            function ($stateParams, Restangular) {
              return Restangular
                .service('photoClassifications')
                .one($stateParams.photoClassificationId)
                .get();
            }
          ],
          checkPermission: permissionCheckResolver('console:tab:websiteContent')
        }
      })
      .state('service-admin.content/photo', {
        url: '/album/{albumId}/photo',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'saPhotoController',
        resolve: {
          currentAlbum: [
            '$stateParams',
            'Album',
            function ($stateParams, Album) {
              return Album.one($stateParams.albumId).get();
            }
          ],
          checkPermission: permissionCheckResolver('console:tab:websiteContent')
        }
      })

    /**
         * vender  pages
         */
      .state('vender', {
        url: '/vender',
        templateUrl: '/view/template/mainpage-wrapper.html',
        controller: 'venderController'
      })
      .state('vender.order/reservation', {
        url: '/reservation',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'saReservationController'
      })
      .state('vender.order/reservation-quotation', {
        url: '/reservation/{reservationId}/quotation',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'saReservationQuotationController',
        resolve: {
          currentReservation: [
            '$stateParams',
            'Reservation',
            function ($stateParams, Reservation) {
              return Reservation.one($stateParams.reservationId).get();
            }
          ]
        }
      })
      .state('vender.order/unordered-reservation', {
        url: '/unordered-reservation',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'saUnorderedReservationController'
      })
      .state('vender.order/unordered-reservation-quotation', {
        url: '/unordered-reservation/{reservationId}/quotation/{quotationId}',
        templateUrl: '/view/page/service-admin/unordered-quotation.html',
        controller: 'saUnorderedReservationQuotationController'
      })
      .state('vender.vender-staff', {
        url: '/vender-staff',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'venderVenderStaffController'
      })
      .state('vender.marketing/notification', {
        url: '/notification',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'saNotificationController'
      })
      .state('vender.account-setting', {
        url: '/account-setting',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'venderAccountSettingController'
      })
      .state('vender.bill/bill', {
        url: '/bill',
        templateUrl: '/view/template/subpage-wrapper.html',
        controller: 'saBillController'
      })

    /**
         * login page
         */
      .state('login', {
        url: '/login',
        templateUrl: '/view/page/login.html',
        controller: 'loginController'
      })
    /**
      * reservation detail page
     */
      .state('reservation-detail', {
        url: '/reservation-detail/:reservationNo',
        templateUrl: '/view/page/life/reservation-detail.html',
        controller: 'reservationDetailController'
      })
      .state('unordered-reservation-detail', {
        url: '/unordered-reservation-detail/:reservationNo',
        templateUrl: '/view/page/life/reservation-detail.html',
        controller: 'unorderedReservationDetailController'
      });

    if (window.history && window.history.pushState) { $locationProvider.html5Mode({ enabled: true, requireBase: false }); }
  }];
